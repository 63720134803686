import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../../components/Page";
import Footer from "../home/elements/Footer";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {},
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingTop: 150,
		paddingBottom: 150,
	},
	container: {
		paddingBottom: 100,
	},
}));

const Condicions = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	if (i18n.language === "ca") {
		return (
			<Page name={"Termes i condicions"} className={classes.root}>
				<Container maxWidth="lg" className={classes.main}>
					<Box>
						<Typography variant="h1">Termes i condicions</Typography>
						<Box my={3} />
						<Typography variant="h3">0. OBJECTE</Typography>
						<Typography variant="body1">
							El present avís legal regula l'ús i utilització del lloc web funerariaferran.com, del qual és titular Funerària Ferran SL (d'ara
							endavant, Funerària Ferran).
							<br />
							L'adquisició de qualssevol dels productes comporta l'acceptació plena i sense reserves de totes i cadascuna de les Condicions
							Generals que s'indiquen, sense perjudici de l'acceptació de les Condicions Particulars que poguessin ser aplicable en adquirir
							determinats productes.
							<br />
							Aquestes Condicions Generals podran ser modificades sense notificació prèvia, per tant, és recomanable llegir atentament el seu
							contingut abans de conducta a l'adquisició de qualsevol dels productes oferts.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">1. IDENTIFICACIÓ</Typography>
						<Typography variant="body1">
							Funerària Ferran, en compliment de la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació i de Comerç
							Electrònic, l'informa que:
							<ul>
								<li>La seva denominació social és: Funerària Ferran SL.</li>
								<li>El seu nom comercial és: Funerària Ferran.</li>
								<li>El seu CIF és: B59945840.</li>
								<li>El seu domicili social està en: Crtra. de Ribes, 8-10 - 08600 Berga - Barcelona (Espanya).</li>
								<li>Està inscrita en el Registre Mercantil de BARCELONA T 21773 , F 100, S 8, H B 26820.</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">2. COMUNICACIONS</Typography>
						<Typography variant="body1">
							Per a comunicar-se amb nosaltres, posem a la seva disposició diferents mitjans de contacte que detallem a continuació:
							<br />
							Tel: 938210310 - Horari d'oficina i gestions administratives: de dilluns a divendres de 9h a 13h i de 16h a 19h; i dissabtes de 9h a
							13h. Gestió de defuncions: disponibilitat les 24h, 7 dies en la setmana.
							<br />
							Email: info@funerariaferran.com.
							<br />
							Totes les notificacions i comunicacions entre els usuaris i Funerària Ferran es consideraran eficaces, amb caràcter general, quan es
							realitzin a través de qualsevol mitjà dels detallats anteriorment.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">3. PRODUCTES</Typography>
						<Typography variant="body1">
							Els productes oferts, juntament amb les característiques principals dels mateixos i el seu preu apareixen en pantalla.
							<br />
							Funerària Ferran es reserva el dret de decidir, a cada moment, els productes que s'ofereixen als Clients. D'aquesta manera,
							Funerària Ferran podrà, en qualsevol moment, afegir nous productes i/o serveis als oferts actualment. Així mateix Funerària Ferran
							es reserva el dret a retirar o deixar d'oferir, en qualsevol moment, i sense previ avís, qualssevol dels productes oferts.
							<br />
							Una vegada dins de funerariaferran.com, i per a accedir a la contractació dels diferents productes, l'Usuari haurà de seguir totes
							les instruccions indicades en el procés de compra, la qual cosa suposarà la lectura i acceptació de totes les condicions generals i
							particulars fixades en funerariaferran.com.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">4. DISPONIBILITAT I SUBSTITUCIONS PRODUCTES</Typography>
						<Typography variant="body1">
							Funerària Ferran pretén garantir la disponibilitat de tots els productes que estan publicitats a través de funerariaferran.com. No
							obstant això, en el cas que qualsevol producte sol·licitat no estigués disponible o si el mateix s'hagués esgotat, se l'informarà el
							client al més aviat possible per via telefònica o correu electrònic al més aviat possible.
							<br />
							De no trobar-se disponible el producte, i havent estat informat d'això el consumidor, Funerària Ferran podrà subministrar un
							producte de similars característiques sense augment de preu, i en aquest cas, el consumidor podrà acceptar-lo o rebutjar-lo exercint
							el seu dret de desistiment i resolució del contracte.
							<br />
							En cas d'indisponibilitat de la totalitat o part de la comanda, i el rebuig dels productes de substitució pel client, el
							reemborsament prèviament abonat, s'efectuarà a través d'ingrés en compte que ens faciliti el client.
							<br />
							Si Funerària Ferran es retardés injustificadament en la devolució de les quantitats abonades, el consumidor podrà reclamar el doble
							de la quantitat deguda.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">5. PROCÉS DE COMPRA</Typography>
						<Typography variant="body1">
							Per a realitzar qualsevol compra en funerariaferran.com, és necessari que el client sigui major d'edat.
							<br />
							Els passos a seguir per a realitzar la compra són:
							<ul>
								<li>Seleccionar producte/s.</li>
								<li>Introduir informació.</li>
								<li>Fer clic en [enviar comanda].</li>
								<li>Recepció confirmació de comanda i indicacions de pagament via mail.</li>
							</ul>
							Així mateix és recomanable que el client imprimeixi i/o guardi una còpia en suport durador de les condicions de venda en realitzar
							la seva comanda, així com també del comprovant de recepció enviat per Funerària Ferran per correu electrònic.
							<br />
							FACTURACIÓ
							<br />
							Juntament amb la seva comanda, rebrà la corresponent factura.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">6. PREUS, DESPESES I IMPOSTOS</Typography>
						<Typography variant="body1">
							Los precios de los productos y servicios que se recogen en funerariaferran.com vindran expressats en Euros tret que s'expressi el
							contrari i estaran garantits durant el temps de validesa d'aquests, excepte error d'impressió i sigui com sigui la destinació de la
							comanda.
							<br />
							PRODUCTES <br />
							Els preus finals de tots els productes, (i en el seu cas, els preus finals per unitat de mesura, quan sigui obligatori) al costat de
							les seves característiques, les ofertes, descomptes, etc., es reflecteixen en cadascun dels articles que apareixen en el catàleg de
							funerariaferran.com.
							<br />
							• Els productes tenen l'IVA inclòs.
							<br />
							Els preus dels productes poden variar en qualsevol moment. En aquest cas, el preu a aplicar serà el vigent en la data de realització
							de la comanda. De qualsevol manera, el cost final serà comunicat al client en el procés de compra electrònica abans que aquest
							formalitzi la seva acceptació.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">7. FORMES DE PAGAMENT</Typography>
						<Typography variant="body1">
							Posem a la seva disposició aquestes formes de pagament:
							<br />
							<Typography variant="h6">Per targeta de crèdit o dèbit</Typography>
							Pot realitzar el pagament de manera immediata a través de la passarel·la de pagament segur SSL del nostre Banc. Si fos necessari
							realitzar qualsevol devolució o cancel·lació de la comanda, l'import es reemborsarà a través de la mateixa targeta de pagament.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">8. POLÍTICA DE DEVOLUCIONES Y DESISTIMIENTO</Typography>
						<Typography variant="body1">
							Segons estableix la normativa, el consumidor i usuari no podrà DESISTIR del contracte d'acord en l'art. 107.2 i 108 del RD1/2007, de
							16 de novembre, pel qual s'aprova el text referit de la Llei General per a la Defensa dels Consumidors i Usuaris.
							<br />
							Aquest dret de desistiment no és aplicable pel següent motiu:
							<ul>
								<li>
									Els contractes de subministrament de productes confeccionats conforme a les especificacions del consumidor i usuari o,
									clarament personalitzats, o que per la seva naturalesa no puguin ser retornats o puguin deteriorar-se o caducar amb
									rapidesa.
								</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">9. ATENCIÓ Al CLIENT I RECLAMACIONS</Typography>
						<Typography variant="body1">
							Per a interposar qualsevol queixa o reclamació davant Funerària Ferran el client haurà de dirigir-se al departament d'atenció al
							client bé en el número de telèfon 938210310, bé a través de l'adreça de correu electrònic ferran@funerariaferran.com.
							<br />
							Segons la Resolució de litigis en línia en matèria de consum de la UE i conforme a l'Art. 14.1 del Reglament (UE) 524/2013,
							l'informem que la Comissió Europea facilita a tots els consumidors una plataforma de resolució de litigis en línia que es troba
							disponible en el següent enllaç: http://ec.europa.eu/consumers/odr/.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">10. LEGISLACIÓ APLICABLE</Typography>
						<Typography variant="body1">
							Les condicions presents es regiran per la legislació espanyola vigent. La llengua utilitzada serà el Castellà.
						</Typography>
					</Box>
				</Container>
				<Footer />
			</Page>
		);
	} else {
		return (
			<Page name={"Terminos y condiciones"} className={classes.root}>
				<Container maxWidth="lg" className={classes.main}>
					<Box>
						<Typography variant="h1">Terminos y condiciones</Typography>
						<Box my={3} />
						<Typography variant="h3">0. OBJECTO</Typography>
						<Typography variant="body1">
							Las presentes Condiciones Generales regulan la adquisición de los productos ofertados en el sitio web funerariaferran.com, del que
							es titular Funerària Ferran SL (en adelante, Funerària Ferran).
							<br />
							La adquisición de cualesquiera de los productos conlleva la aceptación plena y sin reservas de todas y cada una de las Condiciones
							Generales que se indican, sin perjuicio de la aceptación de las Condiciones Particulares que pudieran ser de aplicación al adquirir
							determinados productos.
							<br />
							Estas Condiciones Generales podrán ser modificadas sin notificación previa, por tanto, es recomendable leer atentamente su contenido
							antes de proceder a la adquisición de cualquiera de los productos ofertados. <br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">1. IDENTIFICACIÓN</Typography>
						<Typography variant="body1">
							Funerària Ferran, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio
							Electrónico, le informa de que:
							<ul>
								<li>Su denominación social es: Funerària Ferran SL.</li>
								<li>Su nombre comercial es: Funerària Ferran.</li>
								<li>Su CIF es: B59945840.</li>
								<li>Su domicilio social está en: Crtra. de Ribes, 8-10 - 08600 Berga - Barcelona (Espanya).</li>
								<li>Está inscrita en el Registro Mercantil de BARCELONA  T 21773 , F 100, S 8, H B 26820.</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">2. COMUNICACIONES</Typography>
						<Typography variant="body1">
							Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:
							<br />
							Tfno: 938210310 - Horario de oficina y gestiones administrativas: de lunes a viernes de 9h a 13h y de 16h a 19h; y sábados de 9h a
							13h. Gestión de defunciones: disponibilidad las 24h, 7 días en la semana.
							<br />
							Email: info@funerariaferran.com.
							<br />
							Todas las notificaciones y comunicaciones entre los usuarios y Funerària Ferran se considerarán eficaces, a todos los efectos,
							cuando se realicen a través de cualquier medio de los detallados anteriormente.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">3. PRODUCTOS</Typography>
						<Typography variant="body1">
							Los productos ofertados, junto con las características principales de los mismos y su precio aparecen en pantalla.
							<br />
							Funerària Ferran se reserva el derecho de decidir, en cada momento, los productos y/o servicios que se ofrecen a los Clientes. De
							este modo, Funerària Ferran podrá, en cualquier momento, añadir nuevos productos y/o servicios a los ofertados actualmente. Asimismo
							Funerària Ferran se reserva el derecho a retirar o dejar de ofrecer, en cualquier momento, y sin previo aviso, cualesquiera de los
							productos ofrecidos.
							<br />
							Una vez dentro de funerariaferran.com, y para acceder a la contratación de los distintos productos, el Usuario deberá seguir todas
							las instrucciones indicadas en el proceso de compra, lo cual supondrá la lectura y aceptación de todas las condiciones generales y
							particulares fijadas en funerariaferran.com.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">4. DISPONIBILIDAD Y SUSTITUCIONES PRODUCTOS</Typography>
						<Typography variant="body1">
							Funerària Ferran pretende garantizar la disponibilidad de todos los productos que están publicitados a través de
							funerariaferran.com. No obstante, en el caso de que cualquier producto solicitado no estuviera disponible o si el mismo se hubiera
							agotado, se le informará al cliente lo antes posible por vía telefónica o correo electrónico lo antes posible.
							<br />
							De no hallarse disponible el producto, y habiendo sido informado de ello el consumidor, Funerària Ferran podrá suministrar un
							producto de similares características sin aumento de precio, en cuyo caso, el consumidor podrá aceptarlo o rechazarlo ejerciendo su
							derecho de desistimiento y resolución del contrato.
							<br />
							En caso de indisponibilidad de la totalidad o parte del pedido, y el rechazo de los productos de sustitución por el cliente, el
							reembolso previamente abonado, se efectuará a través de ingreso en cuenta que nos facilite el cliente.
							<br />
							Si Funerària Ferran se retrasara injustificadamente en la devolución de las cantidades abonadas, el consumidor podrá reclamar el
							doble de la cantidad adeudada.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">5. PROCESO DE COMPRA</Typography>
						<Typography variant="body1">
							Para realizar cualquier compra en funerariaferran.com, es necesario que el cliente sea mayor de edad.
							<br />
							Los pasos a seguir para realizar la compra son:
							<ul>
								<li>Seleccionar producto/s.</li>
								<li>Introducir información.</li>
								<li>Hacer click en [enviar pedido].</li>
								<li>Recepción confirmación de pedido e indicaciones de pago vía mail.</li>
							</ul>
							Asimismo es recomendable que el cliente imprima y/o guarde una copia en soporte duradero de las condiciones de venta al realizar su
							pedido, así como también del comprobante de recepción enviado por Funerària Ferran por correo electrónico.
							<br />
							FACTURACIÓN
							<br />
							Junto con su pedido, recibirá la correspondiente factura.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">6 PRECIOS, GASTOS E IMPUESTOS</Typography>
						<Typography variant="body1">
							Los precios de los productos que se recogen en funerariaferran.com vendrán expresados en Euros salvo que se exprese lo contrario y
							estarán garantizados durante el tiempo de validez de los mismos, salvo error de impresión y sea cual sea el destino del pedido.
							<br />
							PRODUCTOS <br />
							Los precios finales de todos los productos, (y en su caso, los precios finales por unidad de medida, cuando sea obligatorio) junto a
							sus características, las ofertas, descuentos, etc., se reflejan en cada uno de los artículos que aparecen en el catálogo de
							funerariaferran.com.
							<br />
							• Los productos tienen el IVA incluido.
							<br />
							Los precios de los productos pueden variar en cualquier momento. En este caso, el precio a aplicar será el vigente en la fecha de
							realización del pedido. De cualquier modo, el coste final será comunicado al cliente en el proceso de compra electrónica antes de
							que éste formalice su aceptación.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">7. FORMAS DE PAGO</Typography>
						<Typography variant="body1">
							Ponemos a su disposición estas formas de pago:
							<br />
							<Typography variant="h6">Por tarjeta de crédito o débito</Typography>
							Puede realizar el pago de manera inmediata a través de la pasarela de pago seguro SSL de nuestro Banco. Si fuera necesario realizar
							cualquier devolución o cancelación del pedido, el importe se reembolsará a través de la misma tarjeta de pago.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">8. POLÍTICA DE DEVOLUCIONES Y DESISTIMIENTO</Typography>
						<Typography variant="body1">
							Según establece la normativa, el consumidor y usuario no podrá DESISTIR del contrato de acuerdo en el art. 107.2 y 108 del RD1/2007,
							de 16 de noviembre, por el que se aprueba el texto referido de la Ley General para la Defensa de los Consumidores y Usuarios.
							<br />
							Este derecho de desistimiento no es aplicable por el siguiente motivo:
							<ul>
								<li>
									• Los contratos de suministro de productos confeccionados conforme a las especificaciones del consumidor y usuario o,
									claramente personalizados, o que por su naturaleza no puedan ser devueltos o puedan deteriorarse o caducar con rapidez.
								</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">9. RESPONSABILIDAD Y EXONERACIÓN DE RESPONSABILIDAD</Typography>
						<Typography variant="body1">
							El consumidor y usuario tiene derecho a la reparación del producto, a su sustitución, a la rebaja del precio o a la resolución del
							contrato, según lo establecido en el Capítulo II Tít. IV Libro II de la Ley General para la Defensa de los Consumidores y Usuarios.
							<br />
							Si el producto no fuera conforme con el contrato, el consumidor podrá optar entre la reparación o la sustitución del producto,
							siendo ambas gratuitas para el consumidor, las cuales se llevarán a cabo en un plazo de tiempo razonable en función de la naturaleza
							de los productos y la finalidad a la que estuvieran destinadas.
							<br />
							Si concluida la reparación y entregado el producto, éste sigue siendo no conforme al contrato, el consumidor podrá exigir su
							sustitución, la rebaja del precio o la resolución del contrato.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">10. ATENCIÓN AL CLIENTE Y RECLAMACIONES</Typography>
						<Typography variant="body1">
							Para interponer cualquier queja o reclamación ante Funerària Ferran el cliente deberá dirigirse al departamento de atención al
							cliente bien en el número de teléfono 938210310, bien a través de la dirección de correo electrónico ferran@funerariaferran.com.
							<br />
							Según la Resolución de litigios en línea en materia de consumo de la UE y conforme al Art. 14.1 del Reglamento (UE) 524/2013, le
							informamos que la Comisión Europea facilita a todos los consumidores una plataforma de resolución de litigios en línea que se
							encuentra disponible en el siguiente enlace: http://ec.europa.eu/consumers/odr/.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">11. LEGISLACIÓN APLICABLE</Typography>
						<Typography variant="body1">
							Las condiciones presentes se regirán por la legislación española vigente. La lengua utilizada será el Castellano.
						</Typography>
					</Box>
				</Container>
				<Footer />
			</Page>
		);
	}
};

export default Condicions;
