import { Button } from "@material-ui/core";
import { makeStyles, Typography, Box } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    categories: {
        display: "flex",
    },
    button: {
        marginRight: 10,
        border: "1px solid " + theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    active: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const Categories = (props) => {
    const { item, setCategoria, categoria } = props;
    const classes = useStyles();

    return (
        <Box my={2} key={item.id} className={classes.categories}>
            <Button
                onClick={() => setCategoria(item.id)}
                className={clsx(
                    classes.button,
                    categoria === item.id && classes.active
                )}
            >
                <Typography>{item.nom}</Typography>
            </Button>
        </Box>
    );
};

export default Categories;
