import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Button,
    Fade,
    CircularProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    deleteFlor,
    getFlor,
    getFlorCategoria,
    updateFlor,
} from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import Thumb from '../../../components/Thumb';
import { Trash } from 'react-feather';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    thumbs: {
        backgroundColor: 'white',
        border: '1px solid ' + theme.palette.primary.main,
        borderRadius: 10,
        padding: 20,
        marginTop: 30,
        marginBottom: 30,
        '& img': {
            margin: 10,
            borderRadius: 10,
        },
    },
}));

const FlorsView = () => {
    const classes = useStyles();
    const { key } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [flor, setFlor] = useState();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [categories, setCategories] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { flor } = await getFlor(key);
            setFlor(flor);
            setLoading(false);
            console.log(flor);
        };
        get();
    }, [key]);

    useEffect(() => {
        const get = async () => {
            const { categories, message } = await getFlorCategoria();
            console.log(message);
            setCategories(categories);
        };
        get();
    }, []);

    const crear = async (values) => {
        const message = await updateFlor(values, key);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    const deleteF = async () => {
        const message = await deleteFlor(key);
        console.log(message);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        setOpen(false);
        navigate(-1, { state: 'values' });
    };

    return (
        <Page className={classes.root} title={'Dashboard'}>
            <Container maxWidth={false} className={classes.container}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Fade in={!loading}>
                        <Formik
                            initialValues={{
                                nom: flor?.nom,
                                preu: flor?.preu,
                                mida: flor?.mida,
                                descripcio: flor?.descripcio,
                                imatge: flor?.imatge,
                                flor_categoria_id: flor?.flor_categoria_id,
                                detalls: flor?.flor_foto,
                            }}
                            validationSchema={Yup.object().shape({
                                nom: Yup.string().required(
                                    'El nom és obligatòri'
                                ),
                                preu: Yup.number().required(
                                    'El preu és obligatòria'
                                ),
                                mida: Yup.string().required(
                                    'La mida és obligatòri'
                                ),
                                descripcio: Yup.string().required(
                                    'La descripcio és obligatòri'
                                ),
                            })}
                            onSubmit={(values) => {
                                crear(values);
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box mb={3}>
                                        <Title
                                            title={'Editar flor'}
                                            subtitle={
                                                'Entra les dades per editar la flor'
                                            }
                                            button={
                                                <IconButton
                                                    className={
                                                        classes.addButton
                                                    }
                                                    onClick={() =>
                                                        setOpen(true)
                                                    }
                                                >
                                                    <Trash />
                                                </IconButton>
                                            }
                                        />
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.nom && errors.nom
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.nom && errors.nom
                                                }
                                                label="Nom"
                                                margin="normal"
                                                name="nom"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.nom}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.preu && errors.preu
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.preu && errors.preu
                                                }
                                                label={'Preu'}
                                                margin="normal"
                                                name="preu"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="number"
                                                value={values.preu}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControl
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="mida">
                                                    Mida
                                                </InputLabel>
                                                <Select
                                                    native
                                                    value={values.mida}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Categoria"
                                                    inputProps={{
                                                        name: 'mida',
                                                        id: 'mida',
                                                    }}
                                                >
                                                    <option
                                                        value={'Detall'}
                                                        key={0}
                                                    >
                                                        Detall
                                                    </option>
                                                    <option
                                                        value={'Petita'}
                                                        key={0}
                                                    >
                                                        Petita
                                                    </option>
                                                    <option
                                                        value={'Mitjana'}
                                                        key={0}
                                                    >
                                                        Mitjana
                                                    </option>
                                                    <option
                                                        value={'Gran'}
                                                        key={0}
                                                    >
                                                        Gran
                                                    </option>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControl
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                <InputLabel htmlFor="flor_categoria_id">
                                                    Categoria
                                                </InputLabel>
                                                <Select
                                                    native
                                                    value={
                                                        values.flor_categoria_id
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Categoria"
                                                    inputProps={{
                                                        name: 'flor_categoria_id',
                                                        id: 'flor_categoria_id',
                                                    }}
                                                >
                                                    <option
                                                        value={0}
                                                        key={0}
                                                    ></option>
                                                    {categories?.map(
                                                        (categoria) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        categoria.id
                                                                    }
                                                                    key={
                                                                        categoria.id
                                                                    }
                                                                >
                                                                    {
                                                                        categoria.nom
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched.descripcio &&
                                                        errors.descripcio
                                                )}
                                                fullWidth
                                                helperText={
                                                    touched.descripcio &&
                                                    errors.descripcio
                                                }
                                                label={'Descripció'}
                                                margin="normal"
                                                name="descripcio"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                multiline
                                                rows={2}
                                                value={values.descripcio}
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <Box mt={2} xs={12}>
                                                <Typography>Imatge</Typography>
                                                <input
                                                    id="imatge"
                                                    name="imatge[]"
                                                    type="file"
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            'imatge',
                                                            event.currentTarget
                                                                .files[0]
                                                        );
                                                    }}
                                                    className="form-control"
                                                />
                                            </Box>
                                            <Box
                                                p={2}
                                                className={classes.thumbs}
                                            >
                                                <Thumb file={values.imatge} />
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography>Detalls</Typography>
                                            <Box mt={2}>
                                                <input
                                                    multiple
                                                    type="file"
                                                    id="detalls"
                                                    name="detalls[]"
                                                    onChange={(event) => {
                                                        console.log(
                                                            values.detalls
                                                        );
                                                        console.log(
                                                            event.currentTarget
                                                                .files
                                                        );
                                                        setFieldValue(
                                                            'detalls',
                                                            event.currentTarget
                                                                .files
                                                        );
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                p={2}
                                                className={classes.thumbs}
                                            >
                                                {values.detalls?.length > 0 &&
                                                    Array.from(
                                                        values.detalls
                                                    ).map((foto) => (
                                                        <Thumb
                                                            file={
                                                                foto.image
                                                                    ? foto.image
                                                                    : foto
                                                            }
                                                        />
                                                    ))}
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box my={2}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {'Editar'}
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Fade>
                )}
            </Container>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Segur que vols eliminar la flor?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={deleteF} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
};

export default FlorsView;
