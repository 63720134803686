import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Container,
    createMuiTheme,
    Fade,
    makeStyles,
    MuiThemeProvider,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { deleteContacte, getContacte } from '../../../database/API';
import MUIDataTable from 'mui-datatables';
import Columns from './columns/ContactColumns';
import Title from '../../../components/Title';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const ContactAdmin = () => {
    const classes = useStyles();
    const [contact, setContact] = useState();
    const [loading, setLoading] = useState(true);
    const columns = Columns();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const get = async () => {
            const { contact, message } = await getContacte();
            setContact(contact);
            console.log(message);
            setLoading(false);
        };

        get();
    }, []);

    const options = {
        enableNestedDataAccess: '.',
        filterType: 'checkbox',
        // onRowClick: (rowData) => {
        //     navigate(`/admin/contact/${rowData[0]}`, {
        //         state: rowData,
        //     });
        // },
        selectableRows: false,
        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                let message = await deleteContacte(contact[item.dataIndex].id);
                enqueueSnackbar(message, {
                    variant: 'success',
                });
                console.log(message);
            });
        },
        textLabels: {
            body: {
                noMatch: 'No hi han entrades',
                toolTip: 'Ordenar',
                columnHeaderTooltip: (column) =>
                    `${'Ordenat per'} ${column.label}`,
            },
            pagination: {
                next: 'Següent pàgina',
                previous: 'Pàgina anterior',
                rowsPerPage: 'Files per pàgina:',
                displayRows: 'de', // 1-10 of 30
            },
            toolbar: {
                search: 'Cercar',
                downloadCsv: 'Descarregar CSV',
                print: 'Imprimir',
                viewColumns: 'Veure columnes',
                filterTable: 'Filtrar taula',
            },
            filter: {
                title: 'FILTRES',
                reset: 'resetejar',
                all: 'Tots',
            },
            viewColumns: {
                title: 'Mostrar columnes',
            },
            selectedRows: {
                text: 'Fila/es eliminades',
                delete: 'Eliminar',
            },
        },
    };

    return (
        <Page className={classes.root} title={'Contacte'}>
            <Container maxWidth={false} className={classes.container}>
                <Title title={'Contactes'} />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <div>
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        data={contact}
                                        columns={columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default ContactAdmin;
