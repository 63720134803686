import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    CircularProgress,
    Fade,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useParams } from 'react-router-dom';
import { getDifunt } from '../../../database/API';
import Title from '../../../components/Title';
import ResumDifunt from './elements/ResumDifunt';
import Notes from './elements/Notes';
import OrdersTable from '../Orders/Table/OrdersTable';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const DifuntView = () => {
    const classes = useStyles();
    const { key } = useParams();
    const [difunt, setDifunt] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { difunt } = await getDifunt(key);
            setDifunt(difunt);
            setLoading(false);
        };
        get();
    }, [key]);

    return (
        <Page className={classes.root} title={'Resum necrològica'}>
            <Container maxWidth={false} className={classes.container}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Fade in={!loading}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Box mb={3}>
                                    <Title title={'Resum necrològica'} />
                                </Box>
                                <ResumDifunt difunt={difunt} />
                                <Grid container spacing={3}>
                                    <Grid item md={12}>
                                        <OrdersTable id={difunt.id} />
                                        <Box pt={3} />
                                        <Notes />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
            </Container>
        </Page>
    );
};

export default DifuntView;
