import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CategoriesAdmin from './Categories/CategoriesAdmin';
import Page from '../../../components/Page';
import Title from '../../../components/Title';
import FloristeriesAdmin from './Floristeries/FloristeriaAdmin';
import EslogansAdmin from './Eslogans/EslogansAdmin';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    text: {
        alignSelf: 'flex-end',
        padding: 4,
    },
    info: {
        marginBottom: 20,
    },
}));

const Configuracio = () => {
    const classes = useStyles();

    return (
        <Page className={classes.root} title={'Configuració'}>
            <Container maxWidth={false} className={classes.container}>
                <Title title={'Configuració'} />
                <Box pt={2} spacing={3}>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <CategoriesAdmin />
                        </Grid>
                        <Grid item md={6}>
                            <FloristeriesAdmin />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <EslogansAdmin />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default Configuracio;
