import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Grid, Fade } from '@material-ui/core';
import { useParams } from 'react-router';
import { Typography } from '@material-ui/core';
import Page from '../../../components/Page';
import { Container } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import Footer from '../home/elements/Footer';
import { getOrder } from '../../../database/API';
import { CheckCircle } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 150,
        paddingBottom: 150,
    },
    container: {
        paddingBottom: 100,
    },
    titol: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            color: theme.palette.success.main,
        },
        '& svg': {
            fill: theme.palette.success.main,
        },
    },
    resum: {
        padding: 20,
        borderRadius: 20,
        backgroundColor: 'white',
        marginTop: 100,
        marginBottom: 100,
    },
    confirmar: {
        border: '1px solid ' + theme.palette.text.secondary,
        borderRadius: 10,
        padding: 20,
    },
    resumFlor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    mediaMini: {
        height: '100%',
        width: '100%',
        borderRadius: 30,
    },
}));

const UrlOK = () => {
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const { id } = useParams();
    const { t } = useTranslation();
    useEffect(() => {
        console.log(id);
        const get = async () => {
            const { newOrder } = await getOrder(id);
            setOrder(newOrder);
            setLoading(false);
            console.log(newOrder);
        };
        get();
    }, [id, setLoading, setOrder]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page name={t('Compra efectuada amb èxit')} className={classes.root}>
            <Container maxWidth="lg" className={classes.main}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Box className={classes.titol}>
                        <CheckCircle />
                        <Typography variant="h2">
                            {t('Compra efectuada amb èxit')}
                        </Typography>
                    </Box>
                    <Typography className={classes.titol}>
                        {t('Moltes gràcies')}
                    </Typography>
                    <Box className={classes.resum}>
                        <Typography variant="h2">
                            {t('Resum de la comanda')} ({order?.unique_id})
                        </Typography>
                        <Box mt={3} />
                        {!loading && (
                            <Fade in={!loading}>
                                <Grid container spacing={0}>
                                    <Grid item md={6}>
                                        <Typography variant="body1">
                                            {t('Nom i cognoms')}: {order?.nom}
                                        </Typography>
                                        <Typography variant="body1">
                                            E-mail: {order?.email}
                                        </Typography>
                                        <Typography variant="body1">
                                            {t('Difunt')}: {order?.difunt.nom}
                                        </Typography>

                                        {order?.factura === '1' && (
                                            <>
                                                <Box mt={3} />
                                                <Typography variant="h3">
                                                    {t('Dades de facturació')}:
                                                </Typography>
                                                <Typography>
                                                    Empresa:{' '}
                                                    {order?.nom_empresa}
                                                </Typography>
                                                <Typography>
                                                    NIF: {order?.dni}
                                                </Typography>
                                                <Typography>
                                                    {t('Adreça')}:{' '}
                                                    {order?.adreca}
                                                </Typography>
                                                <Typography>
                                                    {t('Codi postal')}:{' '}
                                                    {order?.codi_postal}
                                                </Typography>
                                                <Typography>
                                                    {t('Població')}:{' '}
                                                    {order?.poblacio}
                                                </Typography>
                                                <Typography>
                                                    {t('Provincia')}:{' '}
                                                    {order?.provincia}
                                                </Typography>
                                                <Typography>
                                                    {t('País')}: {order?.pais}
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box className={classes.confirmar}>
                                            <Grid container spacing={6}>
                                                <Grid item md={4} xs={12}>
                                                    <Box>
                                                        <img
                                                            src={
                                                                'https://api.funerariaferran.com/public/storage/' +
                                                                order?.flor
                                                                    .imatge
                                                            }
                                                            className={
                                                                classes.mediaMini
                                                            }
                                                            alt="flor"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={8}
                                                    xs={12}
                                                    className={
                                                        classes.resumFlor
                                                    }
                                                >
                                                    <Box>
                                                        <Typography variant="h3">
                                                            {order?.flor.nom}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {
                                                                order?.flor
                                                                    .descripcio
                                                            }
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="h4">
                                                        {t('Preu')}:{' '}
                                                        {order?.flor.preu}€
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Fade>
                        )}
                    </Box>
                </ScrollAnimation>
            </Container>
            <Footer />
        </Page>
    );
};
export default UrlOK;
