import { Box, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ca";
import Icona from "./Icona";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	titol: {
		fontWeight: 700,
	},
	inner: {
		padding: 30,
		backgroundColor: "white",
		borderRadius: 20,
	},
	anys: {
		fontWeight: 800,
		color: theme.palette.secondary.main,
	},
	root: {
		fontSize: "0,5rem",
		border: "2px solid #E5E3DB",
		marginRight: 20,
		justifyContent: "center",
	},
}));
const Necrologica = (props) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { data } = props;
	const { t } = useTranslation();
	const [dataCerimonia, setDataCerimonia] = useState();

	useEffect(() => {
		if (data?.dia_cerimonia && data?.hora_cerimonia) {
			let cerimonia = moment(data?.dia_cerimonia, "YYYY-MM-DD");
			cerimonia.hour(data?.hora_cerimonia?.split(":")[0]);
			cerimonia.minutes(data?.hora_cerimonia?.split(":")[1]);
			setDataCerimonia(moment().diff(cerimonia.subtract(1, "hour"), "minutes"));
		} else {
			setDataCerimonia(-1);
		}
	}, [data]);

	return (
		<Grid item md={props.list ? 6 : 4} sm={12} xs={12} key={data.id}>
			<Box className={classes.inner} display={props.list ? "flex" : ""} justifyContent={props.list ? "space-between" : ""} key={data.id}>
				<Box mb={2}>
					<Typography variant="h3">
						{data.nom} {data?.cognoms}
					</Typography>
					<Typography className={classes.anys}>
						{data.data_naixement && moment(data.data_naixement).format("DD/MM/YYYY") + " - "}
						{moment(data.data_defuncio).format("DD/MM/YYYY")}
					</Typography>
					{data.data_naixement ? (
						<Typography>
							({moment(data.data_defuncio).diff(moment(data.data_naixement), "years")} {t("anys")})
						</Typography>
					) : (
						<Typography style={{ color: "white" }}>-</Typography>
					)}
				</Box>
				<Box flexDirection="row" mt={3}>
					<Tooltip title={t("Veure necrològica")}>
						<IconButton
							onClick={() =>
								navigate("/difunt/" + data.slug, {
									state: { id: data.id },
								})
							}
							size="small"
							className={classes.root}
						>
							<Icona type="info" />
						</IconButton>
					</Tooltip>
					{data.publicat === 1 && dataCerimonia < 0 && (
						<Tooltip title={t("Catàleg floral")}>
							<IconButton
								onClick={() =>
									navigate("/difunt/" + data.slug, {
										state: { id: data.id, to: "flors" },
									})
								}
								size="small"
								className={classes.root}
							>
								<Icona type="flor" />
							</IconButton>
						</Tooltip>
					)}
					{!props.list && dataCerimonia < 0 && (
						<Tooltip title={t("Notes de condol")}>
							<IconButton
								onClick={() =>
									navigate("/difunt/" + data.slug, {
										state: { id: data.id, to: "notes" },
									})
								}
								size="small"
								className={classes.root}
							>
								<Icona type="notes" />
							</IconButton>
						</Tooltip>
					)}
				</Box>
			</Box>
		</Grid>
	);
};

export default Necrologica;
