import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    createMuiTheme,
    Fade,
    IconButton,
    makeStyles,
    MuiThemeProvider,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Refresh } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Tooltip } from "@material-ui/core";
import Columns from "./OrdersColumns";
import OrdersOptions from "./OrdersOptions";
import { getOrders } from "../../../../database/API";
import Title from "../../../../components/Title";

makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 40,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const OrdersTable = (props) => {
    const { id } = props;
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const columns = Columns(orders);
    const options = OrdersOptions(orders);

    useEffect(() => {
        console.log(id);
        const get = async () => {
            const { orders } = await getOrders(id);
            setOrders(orders);
            setLoading(false);
        };

        get();
    }, [id]);

    const refresca = () => {
        const llargada = orders?.length;
        setLoading(true);
        const get = async () => {
            const { orders } = await getOrders(id);
            setOrders(orders);
            setLoading(false);
            const llargada_nova = orders?.length;
            if (llargada_nova - llargada === 0) {
                enqueueSnackbar("No hi ha noves comandes", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar(
                    "S'ha afegit " + (llargada_nova - llargada) + " comanda/es",
                    {
                        variant: "success",
                    }
                );
            }
        };
        get();
    };

    return (
        <>
            <Title
                title="Comandes"
                button={
                    <Box>
                        <Tooltip title="Refrescar">
                            <IconButton onClick={() => refresca()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            />
            <Box pt={2} spacing={3}>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    data={orders}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </>
    );
};

export default OrdersTable;
