import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Footer from "../home/elements/Footer";
import Page from "../../../components/Page";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingTop: 150,
		paddingBottom: 150,
	},
	container: {
		paddingBottom: 100,
	},
}));

const Politica = () => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (i18n.language === "ca") {
		return (
			<Page name={t("Política de Privacitat")} className={classes.root}>
				<Container maxWidth="lg" className={classes.main}>
					<Box>
						<Typography variant="h1">{t("Política de Privacitat")}</Typography>
						<Box my={3} />
						<Typography variant="h3">Qui és el responsable del tractament de les seves dades?</Typography>
						<Typography variant="body1">
							<ul>
								<li>FUNERARIA FERRAN SL.</li>
								<li>B59945840</li>
								<li>Ctra. de Ribes 8-10 - 08600 Berga - Barcelona (Espanya)</li>
								<li>938210310</li>
								<li>privacitat@funerariaferran.com</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">Amb quina finalitat tractem les seves dades personals?</Typography>
						<Typography variant="body1">
							A FUNERARIA FERRAN SL tractem la informació que ens faciliten les persones interessades amb la finalitat de prestar-li els serveis
							que ens ha sol·licitat, donar resposta a les seves sol·licituds d'informació i enviar-li comunicacions comercials. En el cas que no
							faciliti les seves dades personals, no podrem complir amb les funcionalitats descrites anteriorment.
							<br />
							No es prendran decisions automatitzades a partir de les dades facilitades.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">Durant quant de temps conservarem les seves dades?</Typography>
						<Typography variant="body1">Les dades es conservaran mentre l'interessat no sol·liciti la seva supressió.</Typography>
						<Box my={3} />
						<Typography variant="h3">Quina és la legitimació per al tractament de les seves dades?</Typography>
						<Typography variant="body1">
							Indiquem les bases legals per al tractament de les seves dades:
							<br />
							Execució d'un contracte: Gestió de potencials clients que s'han interessat pels nostres productes i/o serveis.
							<br />
							Consentiment de l'interessat: Enviar comunicacions comercials, fins i tot per via electrònica.
							<br />
							Interès legítim del Responsable: Gestió de les dades de contacte professionals (LOPDGDD art.19, RGPD art. 6.1.f).
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">A quins destinataris es comunicaran les seves dades?</Typography>
						<Typography variant="body1">No se cediran dades a tercers, excepte obligació legal.</Typography>
						<Box my={3} />
						<Typography variant="h3">Transferències de dades a tercers països</Typography>
						<Typography variant="body1">No estan previstes transferències de dades a tercers països.</Typography>
						<Box my={3} />
						<Typography variant="h3">Quins són els seus drets quan ens facilita les seves dades?</Typography>
						<Typography variant="body1">
							Qualsevol persona té dret a obtenir confirmació sobre si a FUNERARIA FERRAN SL estem tractant, o no, dades personals que els
							concerneixin.
							<br />
							Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades
							inexactes o, si escau, sol·licitar la seva supressió quan, entre altres motius, les dades ja no siguin necessàries per a les
							finalitats que van ser recollides. També té dret a la portabilitat de les seves dades.
							<br />
							En determinades circumstàncies, els interessats podran sol·licitar la limitació del tractament de les seves dades, en aquest cas
							únicament les conservarem per a l'exercici o la defensa de reclamacions.
							<br />
							En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran oposar-se al
							tractament de les seves dades. En aquest cas, FUNERARIA FERRAN SL deixarà de tractar les dades, excepte per motius legítims
							imperiosos, o l'exercici o la defensa de possibles reclamacions.
							<br />
							Podrà exercir materialment els seus drets de la següent forma: dirigint-se a privacitat@funerariaferran.com o Ctra. de Ribes 8-10,
							08600 Berga - Barcelona.
							<br />
							Quan s’enviïn comunicacions comercials utilitzant l'interès legítim del responsable com a base legal, l'interessat podrà oposar-se
							al tractament de les seves dades per aquesta finalitat.
							<br />
							El consentiment atorgat és per a totes les finalitats indicades la base legal del qual és el consentiment de l'interessat. Vostè té
							dret a retirar aquest consentiment en qualsevol moment, sense afectar la licitud del tractament basat en el consentiment previ a la
							seva retirada.
							<br />
							En el cas que senti vulnerats els seus drets pel que fa a la protecció de les seves dades personals, especialment quan no hagi
							obtingut satisfacció en l’exercici dels seus drets, pot presentar una reclamació davant l’Autoritat de Control en matèria de
							Protecció de Dades competent a través del seu lloc web: www.aepd.es.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">Com hem obtingut les seves dades?</Typography>
						<Typography variant="body1">Les dades personals que tractem a FUNERARIA FERRAN SL provenen de: El propi interessat.</Typography>
					</Box>
				</Container>
				<Footer />
			</Page>
		);
	} else {
		return (
			<Page name={t("Política de Privacitat")} className={classes.root}>
				<Container maxWidth="lg" className={classes.main}>
					<Box>
						<Typography variant="h1">{t("Política de Privacitat")}</Typography>
						<Box my={3} />
						<Typography variant="h3">¿Quién es el responsable del tratamiento de sus datos?</Typography>
						<Typography variant="body1">
							<ul>
								<li>FUNERARIA FERRAN SL.</li>
								<li>B59945840</li>
								<li>Ctra. de Ribes 8-10 - 08600 Berga - Barcelona (Espanya)</li>
								<li>938210310</li>
								<li>privacitat@funerariaferran.com</li>
							</ul>
						</Typography>
						<Box my={3} />
						<Typography variant="h3">¿Con qué finalidad tratamos sus datos personales?</Typography>
						<Typography variant="body1">
							En Funerària Ferran SL tratamos la información que nos facilitan las personas interesadas con el fin de prestarle los servicios que
							nos ha solicitado, atender su solicitud y enviarle comunicaciones comerciales, así como nuestros boletines.
						</Typography>
						<Box my={3} />
						<Typography variant="h3">¿Por cuánto tiempo conservaremos sus datos?</Typography>
						<Typography variant="body1">Los datos se conservarán mientras el interesado no solicite su supresión.</Typography>
						<Box my={3} />
						<Typography variant="h3">¿Cuál es la legitimación para el tratamiento de sus datos?</Typography>
						<Typography variant="body1">
							Le indicamos la base legal para el tratamiento de sus datos:
							<br />
							Ejecución de contrato: Prestación los servicios solicitados.
							<br />
							Interés legítimo del responsable: Envío de comunicaciones comerciales sobre nuestros servicios y productos.
							<br />
							Consentimiento del Interesado: Envío de comunicaciones comerciales sobre nuestros servicios y productos en base al interés
							demostrado.
							<br />
						</Typography>
						<Box my={3} />
						<Typography variant="h3">¿A qué destinatarios se comunicarán sus datos?</Typography>
						<Typography variant="body1">No se cederán datos a terceros, salvo obligación legal.</Typography>
						<Box my={3} />
						<Typography variant="h3">Transferencias de datos a terceros países.</Typography>
						<Typography variant="body1">No están previstas transferencias de datos a terceros países.</Typography>
						<Box my={3} />
						<Typography variant="h3">¿Cuáles son sus derechos cuando nos facilita sus datos?</Typography>
						<Typography variant="body1">
							Cualquier persona tiene derecho a obtener confirmación sobre si en Funerària Ferran SL estamos tratando, o no, datos personales que
							les conciernan.
							<br />
							Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos
							inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que
							fueron recogidos. Igualmente tiene derecho a la portabilidad de sus datos.
							<br />
							En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente
							los conservaremos para el ejercicio o la defensa de reclamaciones.
							<br />
							En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al
							tratamiento de sus datos. En este caso, Funerària Ferran SL dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el
							ejercicio o la defensa de posibles reclamaciones.
							<br />
							Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose al responsable del tratamiento
							<br />
							Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el
							interesado podrá oponerse al tratamiento de sus datos con ese fin.
							<br />
							El consentimiento otorgado es para todas las finalidades indicadas cuya base legal es el consentimiento del interesado. Tiene
							derecho a retirar dicho consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el
							consentimiento previo a su retirada.
							<br />
							En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no
							haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia
							de Protección de Datos competente a través de su sitio web: www.aepd.es.
						</Typography>
					</Box>
				</Container>
				<Footer />
			</Page>
		);
	}
};

export default Politica;
