import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { deleteInformacio, updateInformacio } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import { Trash } from 'react-feather';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));
const InformacioView = () => {
    const classes = useStyles();
    const { key } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const editar = async (values) => {
        console.log(values);
        const message = await updateInformacio(values, key);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1);
    };

    const eliminar = async () => {
        const message = await deleteInformacio(key);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1);
    };

    return (
        <Page className={classes.root} title={'Editar informacio'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        index: state?.index,
                        text: state?.text,
                        text_es: state?.text_es,
                    }}
                    validationSchema={Yup.object().shape({
                        index: Yup.number()
                            .required("L'índex és obligatòri")
                            .min(0, 'Ha de ser un valor positiu'),
                        text: Yup.string().required('El text és obligatòri'),
                        text_es: Yup.string().required(
                            'El text en castellà és obligatòri'
                        ),
                    })}
                    onSubmit={(values) => {
                        editar(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={'Editar informacio'}
                                    button={
                                        <Tooltip title="Eliminar">
                                            <IconButton
                                                onClick={() => eliminar()}
                                            >
                                                <Trash />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.index && errors.index
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.index && errors.index
                                        }
                                        label="Índex"
                                        margin="normal"
                                        name="index"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="number"
                                        value={values.index}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        error={Boolean(
                                            touched.text && errors.text
                                        )}
                                        fullWidth
                                        helperText={touched.text && errors.text}
                                        label={'Text'}
                                        margin="normal"
                                        name="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.text}
                                        rows={10}
                                        multiline
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={4}></Grid>
                                <Grid item md={8}>
                                    <TextField
                                        error={Boolean(
                                            touched.text_es && errors.text_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.text_es && errors.text_es
                                        }
                                        label={'Text castellà'}
                                        margin="normal"
                                        name="text_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text_es"
                                        value={values.text_es}
                                        rows={10}
                                        multiline
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'Editar'}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default InformacioView;
