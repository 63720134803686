import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    text: {
        paddingTop: 5,
        paddingLeft: 10,
    },
    index: {
        width: 20,
    },
    media: {
        width: '100%',
        height: 300,
        objectFit: 'contain',
    },
    preu: {
        fontWeight: 700,
        textAlign: 'right',
    },
    nom: {
        textTransform: 'uppercase',
    },
    flor: {
        border: '1px solid' + theme.palette.primary.main,
        borderRadius: 4,
    },
    imageWrap: {
        width: 300,
        height: 300,
    },
}));

const FlorAdmin = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { item, categoria, difunt } = props;

    return (
        <Grid
            item
            md={4}
            xs={12}
            key={item.id}
            onClick={() =>
                navigate('compra', {
                    state: { id: difunt, flor: item },
                })
            }
            style={{
                display:
                    Number(categoria) !== Number(item.flor_categoria_id) &&
                    'none',
                cursor: 'pointer',
            }}
        >
            <Box p={2} className={classes.flor}>
                <Box className={classes.imageWrap}>
                    <img
                        src={
                            'https://api.funerariaferran.com/public/storage/' +
                            item.imatge
                        }
                        className={classes.media}
                        alt="flor"
                    />
                </Box>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography className={classes.nom}>
                            {item.nom}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.preu}>
                            {item.preu}€
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default FlorAdmin;
