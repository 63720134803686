import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import "moment/locale/ca";
import DifuntEdit from "./DifuntEdit";
import { Button } from "@material-ui/core";
import { deleteImage, getVivo } from "../../../../database/API";
import { useSnackbar } from "notistack";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import DialogVivoRecuerdo from "./DialogVivoRecuerdo";
import DialogEditVivoRecuerdo from "./DialogEditVivoRecuerdo";

const useStyles = makeStyles((theme) => ({
	imatge: {
		width: "100%",
		aspectRatio: 1 / 1,
		height: "auto",
	},
	column: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)",
	},
	nom: {
		textAlign: "center",
		marginBottom: 20,
	},
	eliminar: {
		backgroundColor: theme.palette.danger.main,
		color: "white",
		marginTop: 20,
		"&:hover": {
			backgroundColor: theme.palette.danger.light,
		},
	},
	cancelar: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: 20,
	},
}));

const ResumDifunt = (props) => {
	const classes = useStyles();
	const { difunt } = props;
	const [image, setImage] = useState(difunt.imatge);
	const [openVivo, setOpenVivo] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = React.useState(false);
	const [vivoApi, setVivoApi] = useState();
	const [loadingVivo, setLoadingVivo] = useState(true);
	const [openEditVivo, setOpenEditVivo] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const eliminarFoto = async () => {
		const message = await deleteImage(difunt.id);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setImage("");
	};

	useEffect(() => {
		const vivo = async () => {
			if (difunt?.id_vivo) {
				const { data } = await getVivo(difunt?.id_vivo);
				setVivoApi(data);
				console.log(data);
			}
			setLoadingVivo(false);
		};
		vivo();
	}, [difunt]);

	return (
		<Box className={classes.inner}>
			<Box mb={2}>
				<Grid container spacing={5}>
					<Grid item md={3}>
						<Box className={classes.column} style={{ textAlign: "center" }}>
							<Typography className={classes.nom} variant="h2">
								{difunt.nom} {difunt?.cognoms}
							</Typography>

							<Avatar variant="circular" className={classes.imatge} src={"https://api.funerariaferran.com/public/storage/" + image} />
							{image && (
								<Button className={classes.eliminar} onClick={handleClickOpen}>
									Eliminar fotografia
								</Button>
							)}
						</Box>{" "}
						{loadingVivo ? (
							<Box className={classes.column} mt={3} style={{ textAlign: "center" }}>
								<CircularProgress />
							</Box>
						) : (
							<Box className={classes.column} mt={3} style={{ textAlign: "center" }}>
								{vivoApi?.id ? (
									<>
										<Button
											onClick={() => {
												setOpenEditVivo(true);
											}}
											color="primary"
											variant="contained"
										>
											Editar Vivo Recuerdo
										</Button>
										<Box my={3} />
										<Typography pt={2} variant="caption">
											<a href={"https://" + vivoApi?.url_corta} alt="Enllaç Vivo Recuerdo" target="_blank" rel="noreferrer">
												{vivoApi?.url_corta}
											</a>
										</Typography>
									</>
								) : (
									<>
										<Button
											onClick={() => {
												setOpenVivo(true);
											}}
											color="primary"
											variant="contained"
										>
											Publicar Vivo Recuerdo
										</Button>
										<Box my={3} />
										<Typography pt={2} variant="caption">
											No s'ha trobat la necrològica a Vivo Recuerdo o no està publicada
										</Typography>
									</>
								)}
							</Box>
						)}
					</Grid>
					<Grid item md={9}>
						<Box className={classes.column}>
							<DifuntEdit />
						</Box>
					</Grid>
				</Grid>
			</Box>
			<DialogVivoRecuerdo open={openVivo} setOpen={setOpenVivo} difunt={difunt} setVivoApi={setVivoApi} />
			<DialogEditVivoRecuerdo open={openEditVivo} setOpen={setOpenEditVivo} difunt={vivoApi} setVivoApi={setVivoApi} />
			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{"Segur que vols eliminar la fotografia?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">Si elimines la fotografia, no es podrà recuperar.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} className={classes.cancelar}>
						Cancel·lar
					</Button>
					<Button
						onClick={() => {
							eliminarFoto();
							handleClose();
						}}
						className={classes.eliminar}
						autoFocus
					>
						Eliminar
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ResumDifunt;
