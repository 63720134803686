import React from "react";
import { Box, Container, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Logo from "../../../../components/Logo";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: theme.palette.primary.main,
	},
	container: {
		paddingTop: 50,
		paddingBottom: 50,
		display: "flex",
		alignContent: "center",
		flexDirection: "column",
		alignItems: "center",
	},
	logo: {
		textAlign: "center",
		paddingTop: 20,
		paddingBottom: 20,
	},
}));

const Footer = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();

	return (
		<div className={classes.main}>
			<Container maxWidth="lg" className={classes.container}>
				<Box onClick={() => navigate("/")}>
					<Logo logo="logo_fosc.svg" width="250" />
				</Box>

				<Box mt={5}>
					<Typography>{t("Formem part de")}:</Typography>
				</Box>

				<Box mb={3} display={matches ? "flex" : ""} alignItems={"center"}>
					<Box p={2} textAlign={"center"}>
						<a href="https://funecat.com/" target="_blank" alt="Funecat" rel="noreferrer">
							<Logo logo="funecat.svg" width="170" />
						</a>
					</Box>
					<Box p={2} textAlign={"center"}>
						<a href="https://asfun.cat/" target="_blank" alt="Asfuncat" rel="noreferrer">
							<Logo logo="asfuncat.svg" width="150" />
						</a>
					</Box>
					<Box p={2} textAlign={"center"}>
						<a href="https://www.panasef.com/" target="_blank" alt="Panasef" rel="noreferrer">
							<Logo logo="panasef.svg" width="130" />
						</a>
					</Box>
				</Box>

				<Box my={3} display={"flex"}>
					<Box p={1} textAlign={"center"}>
						<Typography variant="caption">
							<Link to="/avis-legal">{t("AVÍS LEGAL")}</Link>
						</Typography>
					</Box>
					<Box p={1} textAlign={"center"}>
						<Typography variant="caption">
							<Link to="/politica-privacitat">{t("POLÍTICA DE PRIVACITAT")}</Link>
						</Typography>
					</Box>
					<Box p={1} textAlign={"center"}>
						<Typography variant="caption">
							<Link to="/termes-i-condicions">{t("TERMES I CONDICIONS")}</Link>
						</Typography>
					</Box>
				</Box>
				<Box pb={2}>
					<Typography variant="caption">
						{t("Disseny i programació")}:{" "}
						<a href="https://la-padrina.cat" target="_blank" alt="La Padrina" rel="noreferrer">
							La Padrina
						</a>
					</Typography>
				</Box>
			</Container>
		</div>
	);
};

export default Footer;
