import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    icon: { padding: 5 },
    badge: {
        padding: 5,
        borderRadius: 10,
        color: 'white',
        textAlign: 'center',
    },
    espera: {
        backgroundColor: '#ababab',
    },
    completada: {
        backgroundColor: '#8bc34a',
    },
    cancelada: {
        backgroundColor: '#ff1744',
    },
    pagada: {
        backgroundColor: '#03a9f4',
    },
}));

const OrderState = (props) => {
    const classes = useStyles();
    const { state } = props;
    const style =
        state === 'Cancel·lada'
            ? classes.cancelada
            : state === 'Completada'
            ? classes.completada
            : state === 'Pagada'
            ? classes.pagada
            : classes.espera;
    return (
        <Box className={clsx(classes.badge, style)}>
            <Typography style={{ color: 'white' }}>{state}</Typography>
        </Box>
    );
};

export default OrderState;
