import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { Clipboard } from "react-feather";
import moment from "moment";

const Columns = () => {
	const { enqueueSnackbar } = useSnackbar();

	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: false,
				display: true,
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "nom",
			label: "Nom i cognoms",
			options: {
				filter: false,
				sort: true,
			},
		},

		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "telefon",
			label: "Telèfon",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "difunt.nom",
			label: "Nom del difunt",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "difunt.cognoms",
			label: "Cognoms del difunt",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "nota",
			label: "Nota de condol",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "nota",
			label: "Copiar nota",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => (
					<Tooltip title="Copiar">
						<CopyToClipboard
							text={value}
							onCopy={() => {
								enqueueSnackbar("Nota copiada al portapapers", {
									variant: "success",
								});
							}}
						>
							<IconButton>
								<Clipboard />
							</IconButton>
						</CopyToClipboard>
					</Tooltip>
				),
			},
		},
	];
	return columns;
};

export default Columns;
