import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Drawer, Hidden, IconButton, List, Toolbar, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { Close, Dashboard } from "@material-ui/icons";
import navBarStyle from "./NavBarStyle";
import NavBarItem from "./NavBarItem";
import NavBarItemMobile from "./NavBarItemMobile";
import Logo from "../../components/Logo";
import clsx from "clsx";
import { Info } from "../../utils/DifuntContext";
import DifuntHeader from "../../components/DifuntHeader";
import LangSelector from "../../lang/translations/LangSelector";
import { useTranslation } from "react-i18next";

const MainLayout = () => {
	const { difunt } = useContext(Info);
	const classes = navBarStyle();
	const [scrollY, setScrollY] = useState(window.scrollY);
	const [menu, setMenu] = useState(null);
	const [isLogged, setIsLogged] = useState(localStorage.getItem("isLoggedIn") || "");
	let navigate = useNavigate();
	const location = useLocation();
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		setIsLogged(localStorage.getItem("isLoggedIn"));
	}, [isLogged]);

	const logOut = () => {
		localStorage.removeItem("user");
		localStorage.setItem("isLoggedIn", false);
		setIsLogged(false);
		navigate("/");
	};

	const items = [
		{
			title: t("Inici"),
			to: "portada",
		},
		{
			title: t("Necrològiques"),
			to: "necrologiques",
		},
		{
			title: t("Tanatoris"),
			to: "tanatoris",
		},
		{
			title: t("Informació"),
			to: "informacio",
		},
		{
			title: t("Contacte"),
			to: "contacte",
		},
	];

	const openMenu = (event) => {
		setMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	return (
		<div className={classes.root}>
			<AppBar
				elevation={0}
				className={
					location.pathname === "/" || location.pathname.includes("necrologiques-recents")
						? classes.navBar
						: location.pathname.includes("compra")
						? classes.navBarDifunt
						: scrollY < 700
						? classes.navBar
						: classes.navBarDifunt
				}
				style={{
					padding: matches ? 25 : 5,
					paddingBottom: matches ? 0 : 70,
					paddingTop: matches ? 25 : 15,
				}}
			>
				<Toolbar className={classes.toolbar}>
					<Hidden mdDown>
						<List style={{ zIndex: 10 }}>
							{items?.map((item) => (
								<NavBarItem to={item.to} key={item.title} title={item.title} />
							))}
						</List>
					</Hidden>
					<Hidden lgUp>
						<IconButton
							style={{ zIndex: 10 }}
							color="inherit"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={openMenu}
							className={classes.buttonDrawer}
						>
							<MenuIcon />
						</IconButton>
						<Drawer open={menu} onClose={closeMenu}>
							<IconButton>
								<Close onClick={closeMenu} />
							</IconButton>
							<List>
								{items?.map((item) => (
									<NavBarItemMobile to={item.to} key={item.title} title={item.title} closeMenu={closeMenu} />
								))}
							</List>
						</Drawer>
					</Hidden>
					<Box flexGrow={1} />
					<Box>
						{!location.pathname.includes("compra") && (
							<RouterLink to="/" className={clsx(scrollY > 200 && classes.hide, classes.logo, classes.link)}>
								<div style={{ zIndex: 10 }}>
									<Logo logo="logo.svg" width="200" />
								</div>
							</RouterLink>
						)}

						<div className={clsx(!location.pathname.includes("compra") && scrollY < 700 && classes.hide, classes.logo, { zIndex: 0 })}>
							<DifuntHeader difunt={difunt} />
						</div>
					</Box>

					<Box flexGrow={1} />
					<Box zIndex={1000}>
						{isLogged === "true" && (
							<>
								<IconButton color="inherit" onClick={() => navigate("/admin/difunts")}>
									<Dashboard />
								</IconButton>
								<IconButton color="inherit" onClick={() => logOut()}>
									<InputIcon />
								</IconButton>
							</>
						)}
						<LangSelector />
					</Box>
				</Toolbar>
			</AppBar>
			<div className={classes.wrapper}>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
