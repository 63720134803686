import React, { useCallback, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const useStyles = makeStyles((theme) => ({
    galleryWrap: {
        width: 70,
        height: 70,
        overflow: 'hidden',
        textAlign: 'center',
        borderRadius: 50,
    },
    imageGallery: {
        height: 70,
        textAlign: 'center',
    },
    media: {
        height: '100%',
        width: '100%',
    },
    gallery: {
        display: 'flex',
        marginLeft: 20,
        marginRight: 20,
        justifyContent: 'space-evenly',
    },
}));

const FlorImages = (props) => {
    const classes = useStyles();
    const [radius, setRadius] = useState(50);
    const { flor } = props;

    const handleZoomChange = useCallback((shouldZoom) => {
        setRadius(0);
    }, []);
    return (
        <Grid item md={4} xs={12}>
            <Box>
                <Zoom>
                    <img
                        src={
                            'https://api.funerariaferran.com/public/storage/' +
                            flor.imatge
                        }
                        className={classes.media}
                        alt="flor"
                    />
                </Zoom>
            </Box>
            <Box className={classes.gallery}>
                {flor.flor_foto?.map((item) => (
                    <Box className={classes.galleryWrap}>
                        <Zoom>
                            <img
                                onClick={handleZoomChange}
                                src={
                                    'https://api.funerariaferran.com/public/storage/' +
                                    item.image
                                }
                                className={classes.imageGallery}
                                alt="flor"
                            />
                        </Zoom>
                    </Box>
                ))}
            </Box>
        </Grid>
    );
};

export default FlorImages;
