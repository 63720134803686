import { InputBase, MenuItem, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import navBarStyle from "../../layouts/PublicLayout/NavBarStyle";
import i18n from "../../lang";
import { useState } from "react";

const BootstrapInput = withStyles((theme) => ({
	root: {
		"label + &": {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: "relative",
		backgroundColor: "transparent",
		border: "0",
		fontSize: 16,
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		fontFamily: "Red Hat Text",
		"&:focus": {
			borderRadius: 4,
			borderColor: "transparent",
			boxShadow: "0",
			backgroundColor: "transparent",
		},
	},
}))(InputBase);

const LangSelector = () => {
	const classes = navBarStyle();
	const [lang, setLang] = useState(i18n.language);

	const changeLang = (lang) => {
		console.log(lang);
		i18n.changeLanguage(lang);
		setLang(lang);
	};

	return (
		<Select
			labelId="demo-customized-select-label"
			id="demo-customized-select"
			value={lang}
			input={<BootstrapInput />}
			inputProps={{
				classes: {
					icon: classes.arrow,
				},
			}}
			MenuProps={{ disableScrollLock: true }}
		>
			<MenuItem className={classes.menuItem} value={"ca"} onClick={() => changeLang("ca")}>
				Català
			</MenuItem>
			<MenuItem className={classes.menuItem} value={"es"} onClick={() => changeLang("es")}>
				Español
			</MenuItem>
		</Select>
	);
};

export default LangSelector;
