import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import Page from '../../../components/Page';
import Title from '../../../components/Title';
import { Add } from '@material-ui/icons';
import { createPagament, getPagaments } from '../../../database/API';
import MUIDataTable from 'mui-datatables';
import Columns from './Table/PagamentsColumns';
import PagamentsOptions from './Table/PagamentsOptions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    paper: {
        padding: 20,
    },
}));

const Pagament = () => {
    const classes = useStyles();
    const [pagaments, setPagaments] = useState([]);
    const [form, setForm] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const columns = Columns(pagaments);
    const options = PagamentsOptions(pagaments);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { pagaments, message } = await getPagaments();
            setPagaments(pagaments);
            console.log(message);
            setLoading(false);
        };

        get();
    }, []);

    const crear = async (values, actions) => {
        const { form } = await createPagament(values);
        actions.setSubmitting(false);
        actions.resetForm({});
        setForm(form);
        setTimeout(() => document.forms['redsys_form'].submit(), 500);
    };

    return (
        <Page className={classes.root} title={'Pagaments targeta'}>
            <Container maxWidth={false} className={classes.container}>
                <Box mb={3}>
                    <Title title={'Pagaments amb targeta'} />
                </Box>
                <Box pt={2} spacing={3}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <Paper className={classes.paper}>
                                <Formik
                                    initialValues={{
                                        import: '',
                                        concepte: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        import: Yup.number().transform((o, v) =>
                                            parseFloat(v.replace(/,/g, ''))
                                        ),
                                    })}
                                    onSubmit={(values, action) => {
                                        crear(values, action);
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        touched,
                                        values,
                                        validateForm,
                                        setTouched,
                                        setSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={0}>
                                                <Grid item md={12}>
                                                    <TextField
                                                        error={Boolean(
                                                            touched.import &&
                                                                errors.import
                                                        )}
                                                        fullWidth
                                                        helperText={
                                                            touched.import &&
                                                            errors.import
                                                        }
                                                        label="Import"
                                                        margin="normal"
                                                        name="import"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="double"
                                                        value={values.import}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item md={12}>
                                                    <TextField
                                                        error={Boolean(
                                                            touched.concepte &&
                                                                errors.concepte
                                                        )}
                                                        fullWidth
                                                        helperText={
                                                            touched.concepte &&
                                                            errors.concepte
                                                        }
                                                        label={'Concepte'}
                                                        margin="normal"
                                                        rows={10}
                                                        multiline
                                                        name="concepte"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        value={values.concepte}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Box my={2}>
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    size="large"
                                                    onClick={() => {
                                                        validateForm().then(
                                                            (validation) => {
                                                                console.log(
                                                                    errors
                                                                );
                                                                setTouched(
                                                                    validation
                                                                );
                                                                !Object.keys(
                                                                    errors
                                                                ).length &&
                                                                    setOpen(
                                                                        !open
                                                                    );
                                                            }
                                                        );
                                                    }}
                                                    variant="contained"
                                                >
                                                    {'Pagar'}
                                                </Button>
                                            </Box>
                                            <Dialog
                                                open={open}
                                                onClose={() => setOpen(!open)}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                disablePortal
                                                maxWidth="lg"
                                                scroll={'paper'}
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    <Typography variant="h3">
                                                        Confirma les dades
                                                    </Typography>
                                                    {parse(
                                                        form ? form.data : ''
                                                    )}
                                                </DialogTitle>
                                                <DialogContent dividers>
                                                    <Box
                                                        className={
                                                            classes.confirmar
                                                        }
                                                    >
                                                        <Typography>
                                                            Import:{' '}
                                                            {values.import} €
                                                        </Typography>

                                                        <Typography>
                                                            Concepte:{' '}
                                                            {values.concepte}
                                                        </Typography>
                                                    </Box>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        className={
                                                            classes.button
                                                        }
                                                        onClick={() =>
                                                            setOpen(!open)
                                                        }
                                                    >
                                                        Tanca
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        disabled={isSubmitting}
                                                        className={
                                                            classes.button
                                                        }
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Confirmar dades i pagar
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </form>
                                    )}
                                </Formik>
                            </Paper>
                        </Grid>
                        <Grid item md={8}>
                            {!loading ? (
                                <Fade in={!loading}>
                                    <div>
                                        <MUIDataTable
                                            data={pagaments}
                                            columns={columns}
                                            options={options}
                                        />
                                    </div>
                                </Fade>
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default Pagament;
