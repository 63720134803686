import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ListItem, Typography, Link as LinkTo } from '@material-ui/core';
import { Link } from 'react-scroll';
import navBarStyle from './NavBarStyle';

const NavItem = ({ className, to, title, ...rest }) => {
    const classes = navBarStyle();
    const location = useLocation();

    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            {location.pathname === '/' ? (
                <Link
                    className={classes.button}
                    to={to}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={to === 'portada' ? -150 : 0}
                >
                    <Typography className={classes.title}>{title}</Typography>
                </Link>
            ) : (
                <LinkTo
                    className={classes.button}
                    component={RouterLink}
                    to={{
                        pathname: '/',
                    }}
                    state={{ scroll: to }}
                    underline="none"
                >
                    <Typography className={classes.title}>{title}</Typography>
                </LinkTo>
            )}
        </ListItem>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItem;
