import React from "react";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout/NavBar";
import LoginView from "./views/auth/LoginView";
import Home from "./views/public/home/Home";
import { Navigate } from "react-router-dom";
import DifuntView from "./views/admin/Difunts/DifuntView";
import DifuntAdmin from "./views/admin/Difunts/DifuntAdmin";
import DifuntAdd from "./views/admin/Difunts/DifuntAdd";

import TanatoriAdmin from "./views/admin/Tanatoris/TanatoriAdmin";
import TanatoriAdd from "./views/admin/Tanatoris/TanatoriAdd";

import InformacioAdmin from "./views/admin/Informacio/InformacioAdmin";
import InformacioView from "./views/admin/Informacio/InformacioView";
import InformacioAdd from "./views/admin/Informacio/InformacioAdd";

import ContactAdmin from "./views/admin/Contact/ContactAdmin";
import DifuntPublic from "./views/public/Difunts/DifuntPublic";

import FlorsAdmin from "./views/admin/Flors/FlorsAdmin";
import FlorsAdd from "./views/admin/Flors/FlorsAdd";
import FlorsView from "./views/admin/Flors/FlorsView";
import Checkout from "./views/public/CompraFlors/Checkout";
import CategoriesAdd from "./views/admin/Configuracio/Categories/CategoriesAdd";
import CategoriesView from "./views/admin/Configuracio/Categories/CategoriesView";
import OrdersAdmin from "./views/admin/Orders/OrdersAdmin";
import OrderView from "./views/admin/Orders/OrderView";
import TanatoriView from "./views/admin/Tanatoris/TanatoriView";
import NotFound from "./components/NotFound";
import Politica from "./views/public/Legals/Politica";
import NotesAdmin from "./views/admin/Notes/NotesAdmin";
import Configuracio from "./views/admin/Configuracio/Configuracio";
import FloristeriaAdd from "./views/admin/Configuracio/Floristeries/FloristeriaAdd";
import FloristeriaView from "./views/admin/Configuracio/Floristeries/FloristeriaView";
import EsloganAdd from "./views/admin/Configuracio/Eslogans/EsloganAdd";
import UrlOK from "./views/public/CompraFlors/UrlOK";
import UrlKO from "./views/public/CompraFlors/UrlKO";
import NecrologiquesPublic from "./views/public/Difunts/NecrologiquesPublic";
import AvisLegal from "./views/public/Legals/AvisLegal";
import Pagament from "./views/admin/Pagament/Pagament";
import Condicions from "./views/public/Legals/Condicions";
import TotesNecrologiques from "./views/public/Difunts/TotesNecrologiques";

const routes = (user) => [
	{
		path: "/admin",
		element: user ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{ path: "/difunts", element: <DifuntAdmin /> },
			{ path: "/difunts/nou", element: <DifuntAdd /> },
			{ path: "/difunts/:key", element: <DifuntView /> },
			{ path: "/tanatoris", element: <TanatoriAdmin /> },
			{ path: "/tanatoris/nou", element: <TanatoriAdd /> },
			{ path: "/tanatoris/:key", element: <TanatoriView /> },
			{ path: "/informacio", element: <InformacioAdmin /> },
			{ path: "/informacio/nou", element: <InformacioAdd /> },
			{ path: "/informacio/:key", element: <InformacioView /> },
			{ path: "/flors", element: <FlorsAdmin /> },
			{ path: "/flors/nou", element: <FlorsAdd /> },
			{ path: "/flors/:key", element: <FlorsView /> },
			{ path: "/florCategories/nou", element: <CategoriesAdd /> },
			{ path: "/florCategories/:key", element: <CategoriesView /> },
			{ path: "/comandes/:key", element: <OrderView /> },
			{ path: "/comandes/", element: <OrdersAdmin /> },
			{ path: "/contacte/", element: <ContactAdmin /> },
			{ path: "/notes/", element: <NotesAdmin /> },
			{ path: "/configuracio/", element: <Configuracio /> },
			{ path: "/floristeries/nou", element: <FloristeriaAdd /> },
			{ path: "/floristeries/:key", element: <FloristeriaView /> },
			{ path: "/florCategories/:key", element: <CategoriesView /> },
			{ path: "/eslogans/nou", element: <EsloganAdd /> },
			{ path: "/pagament", element: <Pagament /> },
			{ path: "/*", element: <NotFound /> },
		],
	},
	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{
				path: "login",
				element: !user ? <LoginView /> : <Navigate to="/admin/difunts" />,
			},
			{ path: "/", element: <Home /> },
			{ path: "/difunt/:key", element: <DifuntPublic /> },
			{ path: "/difunt/:key/compra", element: <Checkout /> },
			{ path: "/exit/:id", element: <UrlOK /> },
			{ path: "/error/:id", element: <UrlKO /> },
			{ path: "/politica-privacitat", element: <Politica /> },
			{ path: "/necrologiques", element: <NecrologiquesPublic /> },
			{ path: "/necrologiques-recents", element: <TotesNecrologiques /> },
			{ path: "/avis-legal", element: <AvisLegal /> },
			{ path: "/termes-i-condicions", element: <Condicions /> },
			{ path: "*", element: <NotFound /> },
		],
	},
];

export default routes;
