import React, { useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import Notes from '../Difunts/elements/Notes';
import Title from '../../../components/Title';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));
const NotesAdmin = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page className={classes.root} title={'Notes de condol'}>
            <Container maxWidth={false} className={classes.container}>
                <Title title={'Notes de condol'} />

                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Notes />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default NotesAdmin;
