import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const InformacioSkeleton = () => {
    return (
        <Grid item md={8}>
            <Skeleton animation="wave" variant="text" />
            <Skeleton animation="wave" variant="rect" height={350} />
        </Grid>
    );
};

export default InformacioSkeleton;
