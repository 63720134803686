import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from "@material-ui/core";
import { Info } from "../../../utils/DifuntContext";
import { useLocation, useNavigate, useParams } from "react-router";
import { Typography } from "@material-ui/core";
import { createOrder, getDifunt } from "../../../database/API";
import Page from "../../../components/Page";
import Divisor from "../../../components/Divisor";
import { Container } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../home/elements/Footer";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import AnimateHeight from "react-animate-height";
import parse from "html-react-parser";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import FlorImages from "./FlorImages";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
	},
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingTop: 150,
		paddingBottom: 150,
	},
	container: {
		paddingBottom: 100,
	},
	mediaMini: {
		height: "100%",
		width: "100%",
		borderRadius: 30,
	},
	titolDades: {
		textTransform: "uppercase",
		color: theme.palette.text.secondary,
		fontSize: 25,
		fontWeight: 500,
	},
	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
	},
	confirmar: {
		border: "1px solid " + theme.palette.text.secondary,
		borderRadius: 10,
		padding: 20,
		marginTop: 20,
	},
	resumFlor: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
}));

const Input = withStyles((theme) => ({
	root: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderRadius: 30,
				borderColor: theme.palette.background.black,
			},
			"&.Mui-focused fieldset": {
				borderRadius: 30,
			},
			"& .MuiOutlinedInput-input:-webkit-autofill": {
				borderRadius: 30,
			},
		},
		"& .MuiOutlinedInput-input:-webkit-autofill": {
			borderRadius: 30,
		},
	},
}))(TextField);

const Checkout = (props) => {
	const { difunt, setDifunt } = useContext(Info);
	const { state } = useLocation();
	const [height, setHeight] = useState(0);
	const [form, setForm] = useState();
	const [heightCinta, setHeightCinta] = useState(0);
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const location = useLocation();
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		console.log(state);
		const get = async () => {
			const { difunt } = await getDifunt(location.state.id);
			setDifunt(difunt);
		};
		get();

		return () => {
			setDifunt(null);
		};
	}, [location, setDifunt]);

	const comprar = async (values, actions) => {
		values.difunt_id = difunt.id;
		values.flor_id = state.flor.id;
		const { form } = await createOrder(values);
		actions.setSubmitting(false);
		actions.resetForm({});
		setForm(form);

		setTimeout(() => document.forms["redsys_form"].submit(), 500);
	};

	Yup.addMethod(Yup.string, "integer", function () {
		return this.matches(/^\d+$/, t("El camp només pot contenir dígits"));
	});

	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const validate = Yup.object().shape({
		nom: Yup.string().max(255).required(t("El nom és obligatòri")),
		email: Yup.string()
			.email(t("Ha de ser un email vàlid"))
			.max(255)
			.required(t("L'email és obligatòri")),
		telefon: Yup.string()
			.matches(phoneRegExp, t("Telèfon no vàlid"))
			.required(t("El telèfon és obligatòri")),
		adreca: Yup.string()
			.max(200)
			.when("factura", {
				is: true,
				then: Yup.string()
					.required(t("L'adreça és obligatòria"))
					.max(200),
			}),
		poblacio: Yup.string()
			.max(200)
			.when("factura", {
				is: true,
				then: Yup.string()
					.required(t("La població és obligatòria"))
					.max(200),
			}),
		codi_postal: Yup.string()
			.max(45)
			.when("factura", {
				is: true,
				then: Yup.string()
					.required(t("El codi postal és obligatòri"))
					.max(45),
			}),
		provincia: Yup.string()
			.max(45)
			.when("factura", {
				is: true,
				then: Yup.string()
					.required(t("La província és obligatòria"))
					.max(45),
			}),
		dni: Yup.string()
			.max(45)
			.when("factura", {
				is: true,
				then: Yup.string().required(t("El DNI és obligatòri")).max(45),
			}),
		pais: Yup.string()
			.max(45)
			.when("factura", {
				is: true,
				then: Yup.string().required(t("El país és obligatòri")).max(45),
			}),
		nom_empresa: Yup.string()
			.max(200)
			.when("factura", {
				is: true,
				then: Yup.string()
					.required(t("El de l'empresa és obligatòri"))
					.max(200),
			}),
		escrit: Yup.string().max(
			state.values ? 350 : 64,
			t("Has superat el límit de la nota")
		),
	});

	return (
		<Page name="Compra" className={classes.root}>
			<Container maxWidth="lg" className={classes.main}>
				<ScrollAnimation
					animateIn="fadeIn"
					style={{ transition: "0.5s" }}
				>
					<Grid container spacing={10}>
						<FlorImages flor={state.flor} />

						<Grid item md={8} xs={12}>
							<Box pb={4}>
								<Typography variant="h2">
									{state.flor.nom}
								</Typography>
								<Typography variant="body1">
									{state.flor.descripcio}
								</Typography>
							</Box>
							<Divisor />
							<Box pb={4}>
								<Grid container>
									<Grid item xs={4}>
										<Box mb={2}>
											<Typography>
												{t("Categoria")}:
											</Typography>
										</Box>
										<Typography variant="h2">
											{state.flor.flor_categoria.nom}
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Box mb={2}>
											<Typography>
												{t("Mida")}:
											</Typography>
										</Box>
										<Typography
											variant="h2"
											style={{
												textTransform: "capitalize",
											}}
										>
											{state.flor.mida}
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Box mb={2}>
											<Typography>
												{t("Preu (IVA Inclòs)")}:
											</Typography>
										</Box>
										<Typography variant="h2">
											{state.flor.preu}€
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Divisor />
							<Box>
								<Formik
									initialValues={{
										factura: false,
										nom: state.values?.nom,
										telefon: state.values?.telefon,
										email: state.values?.email,
										adreca: "",
										poblacio: "",
										codi_postal: "",
										provincia: "",
										dni: "",
										pais: "",
										nom_empresa: "",
										difunt_id: difunt?.id,
										flor_id: state.flor?.id,
										escrit: state.values?.nota,
									}}
									validationSchema={validate}
									onSubmit={(values, actions) => {
										comprar(values, actions);
									}}
								>
									{({
										errors,
										handleBlur,
										handleChange,
										handleSubmit,
										setFieldValue,
										isSubmitting,
										touched,
										values,
										validateForm,
										setTouched,
									}) => (
										<form onSubmit={handleSubmit}>
											{state.values ? (
												<Box>
													<Input
														error={Boolean(
															touched.escrit &&
																errors.escrit
														)}
														fullWidth
														helperText={
															touched.escrit &&
															errors.escrit
														}
														label={t(
															"Nota de condol"
														)}
														margin="normal"
														name="escrit"
														className={
															classes.input
														}
														onBlur={handleBlur}
														onChange={handleChange}
														multiline
														rows={5}
														type="text"
														value={values.escrit}
														variant="outlined"
														size="small"
													/>
												</Box>
											) : (
												<Box>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	values.opcio
																}
																onChange={(
																	e
																) => {
																	setHeightCinta(
																		e.target
																			.checked
																			? "auto"
																			: 0
																	);
																	setFieldValue(
																		"escrit",
																		""
																	);
																}}
																name="opcio"
															/>
														}
														label={t(
															"Afegir cinta"
														)}
													/>
													<AnimateHeight
														height={heightCinta}
														duration={500}
													>
														<Input
															error={Boolean(
																touched.escrit &&
																	errors.escrit
															)}
															fullWidth
															helperText={
																touched.escrit &&
																errors.escrit
															}
															label={t(
																"Escrit cinta"
															)}
															margin="normal"
															name="escrit"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.escrit
															}
															variant="outlined"
															size="small"
														/>
													</AnimateHeight>
												</Box>
											)}
											<Divisor />
											<Typography
												vairant="h2"
												className={classes.titolDades}
											>
												{t("Les teves dades")}
											</Typography>
											<FormControlLabel
												control={
													<Checkbox
														checked={values.factura}
														onChange={(e) => {
															setFieldValue(
																"factura",
																e.target.checked
															);
															setHeight(
																e.target.checked
																	? "auto"
																	: 0
															);
														}}
														name="factura"
													/>
												}
												label={t("Necessito factura")}
											/>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<Input
														error={Boolean(
															touched.nom &&
																errors.nom
														)}
														fullWidth
														helperText={
															touched.nom &&
															errors.nom
														}
														label={t(
															"Nom i cognoms"
														)}
														margin="normal"
														name="nom"
														className={
															classes.input
														}
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														value={values.nom}
														variant="outlined"
														size="small"
													/>
												</Grid>
											</Grid>
											<Grid container spacing={3}>
												<Grid item sm={6} xs={12}>
													<Input
														error={Boolean(
															touched.telefon &&
																errors.telefon
														)}
														fullWidth
														helperText={
															touched.telefon &&
															errors.telefon
														}
														label={t("Telèfon")}
														margin="normal"
														name="telefon"
														className={
															classes.input
														}
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														value={values.telefon}
														size="small"
														variant="outlined"
													/>
												</Grid>
												<Grid item sm={6} xs={12}>
													<Input
														error={Boolean(
															touched.email &&
																errors.email
														)}
														fullWidth
														helperText={
															touched.email &&
															errors.email
														}
														label="E-mail"
														margin="normal"
														name="email"
														className={
															classes.input
														}
														onBlur={handleBlur}
														onChange={handleChange}
														type="email"
														value={values.email}
														size="small"
														variant="outlined"
													/>
												</Grid>
											</Grid>
											<AnimateHeight
												height={height}
												duration={500}
											>
												<Divisor />

												<Grid container spacing={3}>
													<Grid item md={6} xs={12}>
														<Input
															error={Boolean(
																touched.nom_empresa &&
																	errors.nom_empresa
															)}
															fullWidth
															helperText={
																touched.nom_empresa &&
																errors.nom_empresa
															}
															label={t(
																"Nom empresa"
															)}
															margin="normal"
															name="nom_empresa"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.nom_empresa
															}
															size="small"
															variant="outlined"
														/>
													</Grid>
													<Grid item md={6} xs={12}>
														<Input
															error={Boolean(
																touched.dni &&
																	errors.dni
															)}
															fullWidth
															helperText={
																touched.dni &&
																errors.dni
															}
															label="DNI/NIF"
															margin="normal"
															name="dni"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={values.dni}
															size="small"
															variant="outlined"
														/>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Input
															error={Boolean(
																touched.adreca &&
																	errors.adreca
															)}
															fullWidth
															helperText={
																touched.adreca &&
																errors.adreca
															}
															label={t("Adreça")}
															margin="normal"
															name="adreca"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.adreca
															}
															size="small"
															variant="outlined"
														/>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid item sm={6} xs={12}>
														<Input
															error={Boolean(
																touched.codi_postal &&
																	errors.codi_postal
															)}
															fullWidth
															helperText={
																touched.codi_postal &&
																errors.codi_postal
															}
															label={t(
																"Codi postal"
															)}
															margin="normal"
															name="codi_postal"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.codi_postal
															}
															size="small"
															variant="outlined"
														/>
													</Grid>
													<Grid item sm={6} xs={12}>
														<Input
															error={Boolean(
																touched.poblacio &&
																	errors.poblacio
															)}
															fullWidth
															helperText={
																touched.poblacio &&
																errors.poblacio
															}
															label={t(
																"Població"
															)}
															margin="normal"
															name="poblacio"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.poblacio
															}
															size="small"
															variant="outlined"
														/>
													</Grid>
												</Grid>
												<Grid container spacing={3}>
													<Grid item sm={6} xs={12}>
														<Input
															error={Boolean(
																touched.provincia &&
																	errors.provincia
															)}
															fullWidth
															helperText={
																touched.provincia &&
																errors.provincia
															}
															label={t(
																"Província"
															)}
															margin="normal"
															name="provincia"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={
																values.provincia
															}
															size="small"
															variant="outlined"
														/>
													</Grid>
													<Grid item sm={6} xs={12}>
														<Input
															error={Boolean(
																touched.pais &&
																	errors.pais
															)}
															fullWidth
															helperText={
																touched.pais &&
																errors.pais
															}
															label={t("Pais")}
															margin="normal"
															name="pais"
															className={
																classes.input
															}
															onBlur={handleBlur}
															onChange={
																handleChange
															}
															type="text"
															value={values.pais}
															size="small"
															variant="outlined"
														/>
													</Grid>
												</Grid>
											</AnimateHeight>

											<FormControlLabel
												control={
													<Checkbox
														checked={values.accepta}
														onChange={(e) => {
															setFieldValue(
																"accepta",
																e.target.checked
															);
														}}
														name="accepta"
														required
													/>
												}
												label={
													<>
														{t(
															"He llegit i accepto els "
														)}
														<span
															style={{
																color: "#939393",
																textDecoration:
																	"underline",
															}}
															onClick={() =>
																navigate(
																	"/termes-i-condicions"
																)
															}
														>
															{t(
																"termes i condicions"
															)}
														</span>
													</>
												}
											/>

											<Box my={2}>
												<Button
													color="primary"
													onClick={() => {
														validateForm().then(
															(validation) => {
																console.log(
																	errors
																);
																setTouched(
																	validation
																);
																!Object.keys(
																	errors
																).length &&
																	setOpen(
																		!open
																	);
															}
														);
													}}
													size="small"
													variant="contained"
													className={classes.button}
													disabled={
														!values?.accepta ||
														!values?.telefon ||
														!values?.email ||
														!values.nom
													}
												>
													{t("Enviar comanda")}
												</Button>
											</Box>
											<Dialog
												open={open}
												onClose={() => setOpen(!open)}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
												disablePortal
												maxWidth="md"
												scroll={"paper"}
											>
												<DialogTitle id="alert-dialog-title">
													<Typography variant="h3">
														{t(
															"Confirma les dades"
														)}
													</Typography>
													{parse(
														form ? form.data : ""
													)}
												</DialogTitle>
												<DialogContent dividers>
													<Grid container spacing={3}>
														<Grid item md={6}>
															<Box
																className={
																	classes.confirmar
																}
															>
																<Typography>
																	{t(
																		"Difunt"
																	)}
																	:{" "}
																	{
																		difunt?.nom
																	}{" "}
																	{
																		difunt?.cognoms
																	}
																</Typography>
																<Typography>
																	{t(
																		"Nom i cognoms"
																	)}
																	:{" "}
																	{values.nom}
																</Typography>
																<Typography>
																	{t(
																		"Telèfon"
																	)}
																	:{" "}
																	{
																		values.telefon
																	}
																</Typography>
																<Typography>
																	E-mail:{" "}
																	{
																		values.email
																	}
																</Typography>
																{values.factura && (
																	<>
																		<Typography>
																			Empresa:{" "}
																			{
																				values.nom_empresa
																			}
																		</Typography>
																		<Typography>
																			NIF:{" "}
																			{
																				values.dni
																			}
																		</Typography>
																		<Typography>
																			{t(
																				"Adreça"
																			)}
																			:{" "}
																			{
																				values.adreca
																			}
																		</Typography>
																		<Typography>
																			{t(
																				"Codi postal"
																			)}
																			:{" "}
																			{
																				values.codi_postal
																			}
																		</Typography>
																		<Typography>
																			{t(
																				"Població"
																			)}
																			:{" "}
																			{
																				values.poblacio
																			}
																		</Typography>
																		<Typography>
																			{t(
																				"Província"
																			)}
																			:{" "}
																			{
																				values.provincia
																			}
																		</Typography>
																		<Typography>
																			{t(
																				"Pais"
																			)}
																			:{" "}
																			{
																				values.pais
																			}
																		</Typography>
																	</>
																)}
															</Box>
															{values.escrit && (
																<Box
																	className={
																		classes.confirmar
																	}
																>
																	<Typography>
																		{t(
																			"Escrit"
																		)}
																		:
																	</Typography>
																	<Typography>
																		{
																			values.escrit
																		}
																	</Typography>
																</Box>
															)}
														</Grid>
														<Grid item md={6}>
															<Box
																className={
																	classes.confirmar
																}
															>
																<Grid
																	container
																	spacing={6}
																>
																	<Grid
																		item
																		md={6}
																		xs={12}
																	>
																		<Box>
																			<img
																				src={
																					"https://api.funerariaferran.com/public/storage/" +
																					state
																						.flor
																						.imatge
																				}
																				className={
																					classes.mediaMini
																				}
																				alt="flor"
																			/>
																		</Box>
																	</Grid>
																	<Grid
																		item
																		md={6}
																		xs={12}
																		className={
																			classes.resumFlor
																		}
																	>
																		<Box>
																			<Typography variant="h3">
																				{
																					state
																						.flor
																						.nom
																				}
																			</Typography>
																			<Typography variant="body1">
																				{
																					state
																						.flor
																						.descripcio
																				}
																			</Typography>
																		</Box>
																		<Typography variant="h4">
																			{t(
																				"Preu"
																			)}
																			:{" "}
																			{
																				state
																					.flor
																					.preu
																			}
																			€
																			<Typography>
																				(
																				{t(
																					"IVA Inclòs"
																				)}

																				)
																			</Typography>
																		</Typography>
																	</Grid>
																</Grid>
															</Box>
														</Grid>
													</Grid>
												</DialogContent>
												<DialogActions>
													<Button
														className={
															classes.button
														}
														onClick={() =>
															setOpen(!open)
														}
													>
														Tanca
													</Button>
													<Button
														color="primary"
														size="small"
														disabled={isSubmitting}
														className={
															classes.button
														}
														type="submit"
														variant="contained"
													>
														{t(
															"Confirmar dades i comprar"
														)}
													</Button>
												</DialogActions>
											</Dialog>
										</form>
									)}
								</Formik>
							</Box>
						</Grid>
					</Grid>
				</ScrollAnimation>
			</Container>
			<Footer />
		</Page>
	);
};

export default Checkout;
