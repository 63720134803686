import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Typography,
    Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createFlor, getFlorCategoria } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import Thumb from '../../../components/Thumb';
import { Select } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Input } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const FlorsAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [categories, setCategories] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { categories, message } = await getFlorCategoria();
            console.log(message);
            setCategories(categories);
        };
        get();
    }, []);

    const crear = async (values) => {
        const message = await createFlor(values);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Dashboard'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        nom: '',
                        preu: '',
                        mida: 'Detall',
                        imatge: '',
                        flor_categoria_id: '',
                        descripcio: '',
                        detalls: [],
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string().required('El nom és obligatòri'),
                        preu: Yup.number().required('El preu és obligatòria'),
                        mida: Yup.string().required('La mida és obligatòri'),
                        descripcio: Yup.string().required(
                            'La descripció és obligatòri'
                        ),
                        flor_categoria_id: Yup.number().required(
                            'La categoria és obligatòria'
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={'Crear flor'}
                                    subtitle={
                                        'Entra les dades per guardar una nova flor'
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label="Nom"
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.preu && errors.preu
                                        )}
                                        fullWidth
                                        helperText={touched.preu && errors.preu}
                                        label={'Preu'}
                                        margin="normal"
                                        name="preu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="number"
                                        value={values.preu}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="mida">
                                            Mida
                                        </InputLabel>
                                        <Select
                                            native
                                            value={values.mida}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Categoria"
                                            inputProps={{
                                                name: 'mida',
                                                id: 'mida',
                                            }}
                                        >
                                            <option value={'Detall'} key={0}>
                                                Detall
                                            </option>
                                            <option value={'Petita'} key={1}>
                                                Petita
                                            </option>
                                            <option value={'Mitjana'} key={2}>
                                                Mitjana
                                            </option>
                                            <option value={'Gran'} key={3}>
                                                Gran
                                            </option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="flor_categoria_id">
                                            Categoria
                                        </InputLabel>
                                        <Select
                                            native
                                            value={values.flor_categoria_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Categoria"
                                            inputProps={{
                                                name: 'flor_categoria_id',
                                                id: 'flor_categoria_id',
                                            }}
                                        >
                                            <option value={0} key={0}></option>
                                            {categories?.map((categoria) => {
                                                return (
                                                    <option
                                                        value={categoria.id}
                                                        key={categoria.id}
                                                    >
                                                        {categoria.nom}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.descripcio &&
                                                errors.descripcio
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.descripcio &&
                                            errors.descripcio
                                        }
                                        label={'Descripció'}
                                        margin="normal"
                                        name="descripcio"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        multiline
                                        rows={2}
                                        value={values.descripcio}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box mt={2}>
                                        <Typography>Imatge</Typography>
                                        <input
                                            id="imatge"
                                            name="imatge[]"
                                            type="file"
                                            inputProps={{ accept: 'image/*' }}
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'imatge',
                                                    event.currentTarget.files[0]
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <Thumb file={values.imatge} />
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box mt={2}>
                                        <input
                                            multiple
                                            type="file"
                                            id="detalls"
                                            name="detalls[]"
                                            onChange={(event) => {
                                                setFieldValue(
                                                    'detalls',
                                                    event.currentTarget.files
                                                );
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        {values.detalls?.length > 0 &&
                                            Array.from(values.detalls).map(
                                                (foto) => <Thumb file={foto} />
                                            )}
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'Crear'}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default FlorsAdd;
