import React, { useEffect, useState } from "react";
import { Container, Fade, Grid, Link, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { getTanatoris } from "../../../../database/API";
import Divisor from "../../../../components/Divisor";
import { MapPin } from "react-feather";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import TanatorisSkeleton from "../../../../components/Skeletons/TanatorisSkeleton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		justifyContent: "center",
	},
	desktop: {
		height: "100vh",
	},
	mobile: {
		paddingTop: 50,
		paddingBottom: 50,
	},
	link: {
		fontWeight: 800,
		display: "flex",
		color: theme.palette.secondary.main,
	},
	text: {
		fontWeight: 800,
		color: theme.palette.secondary.main,
	},
	divider: {
		borderWidth: 2,
		borderColor: theme.palette.secondary.main,
		padding: 10,
	},
}));

const Tanatoris = (props) => {
	const classes = useStyles();
	const { setLoadingTanatoris } = props;
	const [tanatoris, setTanatoris] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();

	useEffect(() => {
		const get = async () => {
			const { tanatoris } = await getTanatoris();
			setTanatoris(tanatoris);
			setCount(tanatoris?.length);
			setLoading(false);
			setLoadingTanatoris(false);
		};
		get();
	}, [setLoadingTanatoris]);

	return (
		<Element name="tanatoris" className={clsx(classes.main, matches ? classes.desktop : classes.mobile)}>
			<Container maxWidth="lg">
				<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
					<Grid item md={12}>
						<Typography variant="h2">{t("Tanatoris")}</Typography>
						<Typography>{t("La Funerària Ferran treballa amb")}:</Typography>
					</Grid>
					<Divisor />
					{!loading ? (
						<Fade in={!loading}>
							<Grid container spacing={2}>
								{tanatoris?.map((tanatori) => {
									return (
										<Grid item md={6} key={tanatori.id}>
											<Typography variant="h3">{tanatori.nom}</Typography>
											{/* <Typography>
												{tanatori.sales === 1
													? t("Una sala de vetlla")
													: tanatori.sales === 2
													? t("Dues sales de vetlla")
													: t("Tres sales de vetlla")}
											</Typography> */}
											<Typography>
												{tanatori.carrer} | {tanatori.codi_postal} {tanatori.poblacio}, {tanatori.provincia}
											</Typography>
											<Link className={classes.link} target="_blank" href={tanatori.link}>
												<MapPin />
												<Typography className={classes.text}>{t("Com arribar")}</Typography>
											</Link>
										</Grid>
									);
								})}
							</Grid>
						</Fade>
					) : (
						<TanatorisSkeleton />
					)}

					<Divisor />
				</ScrollAnimation>
			</Container>
		</Element>
	);
};

export default Tanatoris;
