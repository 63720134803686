import React from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ListItem, Typography, Link as LinkTo } from "@material-ui/core";
import { Link } from "react-scroll";
import navBarStyle from "./NavBarStyle";

const NavItemMobile = ({ className, to, title, closeMenu, ...rest }) => {
    const classes = navBarStyle();
    const location = useLocation();

    const onClick = () => {
        closeMenu();
    };

    return (
        <ListItem
            className={clsx(classes.itemMobile, className)}
            disableGutters
            {...rest}
        >
            {location.pathname === "/" ? (
                <Link
                    className={classes.buttonMobile}
                    to={to}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={() => onClick()}
                >
                    <Typography className={classes.titleMobile}>
                        {title}
                    </Typography>
                </Link>
            ) : (
                <LinkTo
                    className={classes.buttonMobile}
                    component={RouterLink}
                    to={{
                        pathname: "/",
                    }}
                    state={{ scroll: to }}
                    underline="none"
                >
                    <Typography className={classes.titleMobile}>
                        {title}
                    </Typography>
                </LinkTo>
            )}
        </ListItem>
    );
};

NavItemMobile.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
};

export default NavItemMobile;
