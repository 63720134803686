import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import { useNavigate } from 'react-router';
import OrderState from '../../../../components/OrderState';

const Columns = (pagaments) => {
    const navigate = useNavigate();
    const columns = [
        {
            name: 'unique_id',
            label: 'ID únic',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'created_at',
            label: 'Data',
            options: {
                filter: false,
                sort: true,
                sortOrder: 'desc',
                customBodyRender: (value) =>
                    moment(new Date(value)).format('DD/MM/YYYY H:m'),
            },
        },

        {
            name: 'import',
            label: 'Import',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return value + ' €';
                },
            },
        },
        {
            name: 'concepte',
            label: 'Concepte',
            options: {
                filter: false,
                sort: true,
            },
        },

        {
            name: 'order_state.state',
            label: 'Estat',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <OrderState state={value} />;
                },
            },
        },
        // {
        //     name: 'id',
        //     label: 'Accions',
        //     options: {
        //         filter: false,
        //         sort: false,
        //         empty: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             return (
        //                 <IconButton
        //                     onClick={() =>
        //                         navigate(
        //                             `/admin/comandes/${pagaments[dataIndex].id}`,
        //                             {
        //                                 state: pagaments[dataIndex],
        //                             }
        //                         )
        //                     }
        //                 >
        //                     <Edit />
        //                 </IconButton>
        //             );
        //         },
        //     },
        // },
    ];
    return columns;
};

export default Columns;
