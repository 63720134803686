import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";

const PerfilSkeleton = () => {
    // const matches = useMediaQuery("(min-width:960px)");
    return (
        <Grid container spacing={10}>
            <Grid item md={3} xs={12}>
                <Skeleton
                    animation="wave"
                    variant="rect"
                    height={200}
                    style={{ borderRadius: 20 }}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Grid item md={12}>
                    <Box pb={2}>
                        <Skeleton
                            animation="wave"
                            variant="rect"
                            height={45}
                            style={{ borderRadius: 10 }}
                        />
                    </Box>

                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />
                    <Skeleton animation="wave" variant="text" />

                    <Grid container spacing={3} style={{ marginTop: 20 }}>
                        <Grid item md={6}>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                height={50}
                                style={{ borderRadius: 30 }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                height={50}
                                style={{ borderRadius: 30 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
    );
};

export default PerfilSkeleton;
