import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { Dashboard } from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as Logo } from '../../theme/icons/logo.svg';

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        width: 60,
        height: 60,
    },
    icon: {
        fill: 'white',
        width: 200,
        height: 40,
    },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const [isLogged, setIsLogged] = useState(
        localStorage.getItem('isLoggedIn') || ''
    );
    let navigate = useNavigate();

    useEffect(() => {
        setIsLogged(localStorage.getItem('isLoggedIn'));
    }, []);

    const logOut = () => {
        localStorage.removeItem('user');
        localStorage.setItem('isLoggedIn', false);
        setIsLogged(false);
        navigate('/');
    };

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <RouterLink to="/">
                    <SvgIcon
                        className={classes.icon}
                        component={Logo}
                        viewBox="0 0 24 8"
                    />
                </RouterLink>
                <Box flexGrow={1} />
                <Hidden>
                    {isLogged ? (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={() => navigate('/')}
                            >
                                <Dashboard />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => logOut()}
                            >
                                <InputIcon />
                            </IconButton>
                        </>
                    ) : (
                        <IconButton
                            color="inherit"
                            onClick={() => navigate('/admin/difunts')}
                        >
                            <Dashboard />
                        </IconButton>
                    )}
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

export default TopBar;
