import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    text: {
        paddingTop: 5,
        paddingLeft: 10,
    },
    index: {
        width: 20,
    },
}));

const Icona = (props) => {
    const classes = useStyles();
    const { item } = props;
    const { i18n } = useTranslation();

    return (
        <Box
            key={item.id}
            className={classes.item}
            justifyContent="flex-start"
            display="flex"
        >
            <Typography variant="h2" className={classes.index}>
                {item.index}.
            </Typography>
            {i18n.language === 'es' ? (
                <Typography className={classes.text}>
                    {item.text_es.split('\n').map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        );
                    })}
                </Typography>
            ) : (
                <Typography className={classes.text}>
                    {item.text.split('\n').map(function (item, idx) {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                            </span>
                        );
                    })}
                </Typography>
            )}
        </Box>
    );
};

export default Icona;
