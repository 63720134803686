import { makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
    hr: {
        marginTop: 50,
        marginBottom: 50,
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
    },
}));
const Divisor = () => {
    const classes = useStyles();
    return <hr className={classes.hr} />;
};

export default Divisor;
