import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createTanatori } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const TanatoriAdd = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const crear = async (values) => {
        const message = await createTanatori(values);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Dashboard'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        nom: '',
                        sales: '',
                        carrer: '',
                        poblacio: '',
                        codi_postal: '',
                        provincia: '',
                        link: '',
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string()
                            .max(255)
                            .required('El nom és obligatòri'),
                        sales: Yup.number().required(
                            'El número de sales és obligatòri'
                        ),
                        carrer: Yup.string().required(
                            'El carrer és obligatòri'
                        ),
                        poblacio: Yup.string().required(
                            'La població és obligatòria'
                        ),
                        codi_postal: Yup.number().required(
                            'El codi postal és obligatòri'
                        ),
                        provincia: Yup.string().required(
                            'La província és obligatòria'
                        ),
                        link: Yup.string().required('Enllaç obligatòri'),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={'Crear tanatori'}
                                    subtitle={
                                        'Entra les dades per guardar una nova entrada'
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label="Nom"
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nom}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.sales && errors.sales
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.sales && errors.sales
                                        }
                                        label={'Sales'}
                                        margin="normal"
                                        name="sales"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.sales}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.carrer && errors.carrer
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.carrer && errors.carrer
                                        }
                                        label={'Carrer'}
                                        margin="normal"
                                        name="carrer"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.carrer}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.poblacio && errors.poblacio
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.poblacio && errors.poblacio
                                        }
                                        label="Població"
                                        margin="normal"
                                        name="poblacio"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.poblacio}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.codi_postal &&
                                                errors.codi_postal
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.codi_postal &&
                                            errors.codi_postal
                                        }
                                        label={'Còdi Postal'}
                                        margin="normal"
                                        name="codi_postal"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.codi_postal}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.provincia &&
                                                errors.provincia
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.provincia &&
                                            errors.provincia
                                        }
                                        label={'Província'}
                                        margin="normal"
                                        name="provincia"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.provincia}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.link && errors.link
                                        )}
                                        fullWidth
                                        helperText={touched.link && errors.link}
                                        label="Enllaç mapa"
                                        margin="normal"
                                        name="link"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.link}
                                    />
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'Crear'}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default TanatoriAdd;
