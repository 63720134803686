const Columns = () => {
    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "sales",
            label: "Sales",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "carrer",
            label: "Carrer",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "poblacio",
            label: "Població",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "codi_postal",
            label: "Còdi Postal",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "provincia",
            label: "Província",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];
    return columns;
};

export default Columns;
