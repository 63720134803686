import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Portada from "./elements/Portada";
import Necrologiques from "./elements/Necrologiques";
import QuiSom from "./elements/QuiSom";
import Degradat from "../../../components/Degradat";
import Tanatoris from "./elements/Tanatoris";
import Informacions from "./elements/Informacions";
import Contacte from "./elements/Contacte";
import Footer from "./elements/Footer";
import { scroller } from "react-scroll";
import { useLocation } from "react-router";
import { animated, useSpring } from "@react-spring/web";
import { CircularProgress } from "@material-ui/core";
import Loading from "../../../components/Loading";
import Video from "./elements/Video";

const DashboardView = ({ className, onMobileNavOpen, ...rest }) => {
	let location = useLocation();
	const [loading, setLoading] = useState(true);
	const [loadingPortada, setLoadingPortada] = useState(true);
	const [loadingNecro, setLoadingNecro] = useState(true);
	const [loadingTanatoris, setLoadingTanatoris] = useState(true);
	const [loadingInfo, setLoadingInfo] = useState(true);
	const [{ opacity }, set] = useSpring(() => ({
		opacity: 0,
	}));

	useEffect(() => {
		setTimeout(() => {
			if (location.state)
				scroller.scrollTo(location.state?.scroll, {
					offset: location.state?.scroll === "portada" ? -150 : 0,
				});
			else window.scrollTo(0, 0);
		}, 200);
	}, [location]);

	useEffect(() => {
		if (!loadingPortada && !loadingNecro && !loadingTanatoris && !loadingInfo) {
			setLoading(false);
		}
	}, [loadingInfo, loadingNecro, loadingPortada, loadingTanatoris]);

	useEffect(() => {
		set.start({ opacity: loading ? 0 : 1 });
	}, [loading, set]);

	return (
		<div>
			<animated.div style={{ opacity }}>
				<Box maxWidth="lg">
					<Portada setLoadingPortada={setLoadingPortada} />
					<Degradat type="up" />
					<QuiSom />
					<Degradat type="down" />
					<Necrologiques setLoadingNecro={setLoadingNecro} />
					<Degradat type="up" />
					<Tanatoris setLoadingTanatoris={setLoadingTanatoris} />
					<Degradat type="down" />
					<Informacions setLoadingInfo={setLoadingInfo} />
					<Degradat type="up" />
					<Contacte />
					<Degradat type="down" />
					<Video />
					<Footer />
				</Box>
			</animated.div>
			{loading && <Loading />}
		</div>
	);
};

export default DashboardView;
