import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { getEslogans, getEslogansPublic } from '../../../../database/API';
import { animated, useSpring } from '@react-spring/web';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/fons-portada.jpg")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    main: {
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text: {
        textAlign: 'center',
    },
}));

const Portada = (props) => {
    const classes = useStyles();
    const { setLoadingPortada } = props;
    const [eslogans, setEslogans] = useState();
    const [loading, setLoading] = useState(true);
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        const get = async () => {
            const { eslogans } = await getEslogansPublic();
            setEslogans(eslogans);
            setLoading(false);
            setLoadingPortada(false);
            set.start({ opacity: loading ? 0 : 1 });
        };
        get();
    }, [loading, set, setLoadingPortada]);

    return (
        <Box height="100vh">
            <ScrollAnimation
                animateIn="fadeIn"
                delay={300}
                style={{ transition: '0.5s' }}
            >
                <Element name="portada">
                    <Container className={classes.root}>
                        <Box className={classes.main}>
                            {!loading ? (
                                <animated.div style={{ opacity }}>
                                    <Typography
                                        className={classes.text}
                                        variant="h1"
                                    >
                                        {
                                            eslogans[
                                                Math.round(
                                                    Math.random() *
                                                        (eslogans.length - 1)
                                                )
                                            ]?.titol
                                        }
                                    </Typography>
                                </animated.div>
                            ) : (
                                'Loading'
                            )}
                        </Box>
                    </Container>
                </Element>
            </ScrollAnimation>
        </Box>
    );
};

export default Portada;
