import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { animated, useSpring } from '@react-spring/web';
import Footer from '../home/elements/Footer';
import Necrologiques from '../home/elements/Necrologiques';

const NecrologiquesPublic = () => {
    const [loadingNecro, setLoadingNecro] = useState(true);
    const [{ opacity }, set] = useSpring(() => ({
        opacity: 0,
    }));

    useEffect(() => {
        set.start({ opacity: loadingNecro ? 0 : 1 });
    }, [loadingNecro, set]);

    return (
        <animated.div style={{ opacity }}>
            <Box maxWidth="lg">
                <Necrologiques setLoadingNecro={setLoadingNecro} />
                <Footer />
            </Box>
        </animated.div>
    );
};

export default NecrologiquesPublic;
