import { IconButton } from "@material-ui/core";
import { CheckCircle, Edit } from "@material-ui/icons";
import moment from "moment";
import { useNavigate } from "react-router";
import OrderState from "../../../../components/OrderState";

const Columns = (orders) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
			},
		},
		{
			name: "difunt.nom",
			label: "Nom del difunt",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "difunt.cognoms",
			label: "Cognoms del difunt",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "flor.nom",
			label: "Flor",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "flor.preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => value + "€",
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "telefon",
			label: "Telèfon",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},

		{
			name: "order_state.state",
			label: "Estat",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => {
					return <OrderState state={value} />;
				},
			},
		},
		{
			name: "factura",
			label: "Sol·licita factura",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => (value === 1 ? <CheckCircle style={{ fill: "green" }} /> : ""),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(`/admin/comandes/${orders[dataIndex].id}`, {
									state: orders[dataIndex],
								})
							}
						>
							<Edit />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default Columns;
