import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createInformacio } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const DifuntApp = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const crear = async (values) => {
        console.log(values);
        const message = await createInformacio(values);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1);
    };

    return (
        <Page className={classes.root} title={'Dashboard'}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    initialValues={{
                        index: '',
                        text: '',
                        text_es: '',
                    }}
                    validationSchema={Yup.object().shape({
                        index: Yup.number()
                            .required("L'index és obligatòri")
                            .min(0, 'Ha de ser un valor positiu'),
                        text: Yup.string().required('El text és obligatòri'),
                        text_es: Yup.string().required(
                            'El text en castellà és obligatòri'
                        ),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={'Crear informació'}
                                    subtitle={
                                        'Entra les dades per guardar una nova entrada'
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.index && errors.index
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.index && errors.index
                                        }
                                        label="Índex"
                                        margin="normal"
                                        name="index"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="number"
                                        value={values.index}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        error={Boolean(
                                            touched.text && errors.text
                                        )}
                                        fullWidth
                                        helperText={touched.text && errors.text}
                                        label={'Text'}
                                        margin="normal"
                                        rows={10}
                                        multiline
                                        name="text"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.text}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={4}></Grid>
                                <Grid item md={8}>
                                    <TextField
                                        error={Boolean(
                                            touched.text_es && errors.text_es
                                        )}
                                        fullWidth
                                        helperText={
                                            touched.text_es && errors.text_es
                                        }
                                        label={'Text castellà'}
                                        margin="normal"
                                        name="text_es"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text_es"
                                        value={values.text_es}
                                        rows={10}
                                        multiline
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {'Crear'}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default DifuntApp;
