import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const FlorsSkeleton = () => {
    return (
        <Grid container spacing={10}>
            <Grid item md={4}>
                <Skeleton animation="wave" variant="rect" height={365} />
            </Grid>
            <Grid item md={4}>
                <Skeleton animation="wave" variant="rect" height={365} />
            </Grid>
            <Grid item md={4}>
                <Skeleton animation="wave" variant="rect" height={365} />
            </Grid>
        </Grid>
    );
};

export default FlorsSkeleton;
