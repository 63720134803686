import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import Footer from '../home/elements/Footer';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 150,
        paddingBottom: 150,
    },
    container: {
        paddingBottom: 100,
    },
}));

const AvisLegal = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    if (i18n.language === 'ca') {
        return (
            <Page name={t('Avís Legal')} className={classes.root}>
                <Container maxWidth="lg" className={classes.main}>
                    <Box>
                        <Typography variant="h1">{t('Avís legal')}</Typography>
                        <Box my={3} />
                        <Typography variant="h3">0. OBJECTE</Typography>
                        <Typography variant="body1">
                            El present avís legal regula l'ús i utilització del
                            lloc web funerariaferran.com, del qual és titular
                            Funerària Ferran SL (d'ara endavant, Funerària
                            Ferran).
                            <br />
                            La navegació pel lloc web de Funerària Ferran li
                            atribueix la condició d'USUARI del mateix i comporta
                            la seva acceptació plena i sense reserves de totes i
                            cadascuna de les condicions publicades en aquest
                            avís legal, advertint que aquestes condicions podran
                            ser modificades sense notificació prèvia per part de
                            Funerària Ferran, i en aquest cas es procedirà a la
                            seva publicació i avís amb la màxima antelació
                            possible.
                            <br />
                            Per això és recomanable llegir atentament el seu
                            contingut en cas de desitjar accedir i fer ús de la
                            informació i dels serveis oferts des d'aquest lloc
                            web.
                            <br />
                            L'usuari a més, s'obliga a fer un ús correcte del
                            lloc web de conformitat amb les lleis, la bona fe,
                            l'ordre públic, els usos del trànsit i el present
                            Avís Legal, i respondrà enfront de Funerària Ferran
                            o enfront de tercers, de qualssevol danys i
                            perjudicis que poguessin causar-se com a
                            conseqüència de l'incompliment d'aquesta obligació.
                            <br />
                            Qualsevol utilització diferent a l'autoritzada està
                            expressament prohibida, podent Funerària Ferran
                            denegar o retirar l'accés i el seu ús en qualsevol
                            moment.
                            <br />
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">1. IDENTIFICACIÓ</Typography>
                        <Typography variant="body1">
                            Funerària Ferran, en compliment de la Llei 34/2002,
                            d'11 de juliol, de Serveis de la Societat de la
                            Informació i de Comerç Electrònic, l'informa que:
                            <ul>
                                <li>
                                    La seva denominació social és: Funerària
                                    Ferran SL.
                                </li>
                                <li>
                                    El seu nom comercial és: Funerària Ferran.
                                </li>
                                <li>El seu CIF és: B59945840.</li>
                                <li>
                                    El seu domicili social està en: Crtra. de
                                    Ribes, 8-10 - 08600 Berga - Barcelona
                                    (Espanya).
                                </li>
                                <li>
                                    Està inscrita en el Registre Mercantil de
                                    BARCELONA T 21773 , F 100, S 8, H B 26820.
                                </li>
                            </ul>
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">2. COMUNICACIONS</Typography>
                        <Typography variant="body1">
                            Per a comunicar-se amb nosaltres, posem a la seva
                            disposició diferents mitjans de contacte que
                            detallem a continuació:
                            <br />
                            Tel: 938210310 - Horari d'oficina i gestions
                            administratives: de dilluns a divendres de 9h a 13h
                            i de 16h a 19h; i dissabtes de 9h a 13h. Gestió de
                            defuncions: disponibilitat les 24h, 7 dies en la
                            setmana.
                            <br />
                            Email: info@funerariaferran.com.
                            <br />
                            Totes les notificacions i comunicacions entre els
                            usuaris i Funerària Ferran es consideraran eficaces,
                            amb caràcter general, quan es realitzin a través de
                            qualsevol mitjà dels detallats anteriorment.
                            <br />
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">
                            3. CONDICIONS DE ACCÉS I UTILITZACIÓ
                        </Typography>
                        <Typography variant="body1">
                            El lloc web i els seus serveis són d'accés lliure i
                            gratuït. No obstant això, Funerària Ferran pot
                            condicionar la utilització d'alguns dels serveis
                            oferts en el seu web al previ emplenament del
                            corresponent formulari.
                            <br />
                            L'usuari garanteix l'autenticitat i actualitat de
                            totes aquelles dades que comuniqui a Funerària
                            Ferran i serà l'únic responsable de les
                            manifestacions falses o inexactes que realitzi.
                            <br />
                            L'usuari es compromet expressament a fer un ús
                            adequat dels continguts i serveis de Funerària
                            Ferran i a no emprar-los per a, entre altres:
                            <br />
                            <ul>
                                <li>
                                    a) Difondre continguts delictius, violents,
                                    pornogràfics, racistes, xenòfobs, ofensius,
                                    d'apologia del terrorisme o, en general,
                                    contraris a la llei o a l'ordre públic.
                                </li>
                                <li>
                                    b) Introduir en la xarxa virus informàtics o
                                    realitzar actuacions susceptibles d'alterar,
                                    espatllar, interrompre o generar errors o
                                    danys en els documents electrònics, dades o
                                    sistemes físics i lògics de Funerària Ferran
                                    o de terceres persones; així com
                                    obstaculitzar l'accés d'altres usuaris al
                                    lloc web i als seus serveis mitjançant el
                                    consum massiu dels recursos informàtics a
                                    través dels quals Funerària Ferran presta
                                    els seus serveis.
                                </li>
                                <li>
                                    c) Intentar accedir als comptes de correu
                                    electrònic d'altres usuaris o a àrees
                                    restringides dels sistemes informàtics de
                                    Funerària Ferran o de tercers i, si escau,
                                    extreure informació.
                                </li>
                                <li>
                                    d) Vulnerar els drets de propietat
                                    intel·lectual o industrial, així com violar
                                    la confidencialitat de la informació de
                                    Funerària Ferran o de tercers.
                                </li>
                                <li>
                                    e) Suplantar la identitat de qualsevol altre
                                    usuari.
                                </li>
                                <li>
                                    f) Reproduir, copiar, distribuir, posar a la
                                    disposició de, o qualsevol altra forma de
                                    comunicació pública, transformar o modificar
                                    els continguts, tret que es compti amb
                                    l'autorització del titular dels
                                    corresponents drets o això resulti legalment
                                    permès.
                                </li>
                                <li>
                                    g) Recaptar dades amb finalitat publicitària
                                    i de remetre publicitat de qualsevol classe
                                    i comunicacions amb finalitats de venda o
                                    unes altres de naturalesa comercial sense
                                    que mediï la seva prèvia sol·licitud o
                                    consentiment.
                                </li>
                            </ul>
                            Tots els continguts del lloc web, com a textos,
                            fotografies, gràfics, imatges, icones, tecnologia,
                            programari, així com el seu disseny gràfic i codis
                            font, constitueixen una obra la propietat de la qual
                            pertany a Funerària Ferran, sense que puguin
                            entendre's cedits a l'usuari cap dels drets
                            d'explotació sobre els mateixos més enllà de
                            l'estrictament necessari per al correcte ús de la
                            web.
                            <br />
                            En definitiva, els usuaris que accedeixin a aquest
                            lloc web poden visualitzar els continguts i
                            efectuar, si escau, còpies privades autoritzades
                            sempre que els elements reproduïts no siguin cedits
                            posteriorment a tercers, ni s'instal·lin a servidors
                            connectats a xarxes, ni siguin objecte de cap mena
                            d'explotació.
                            <br />
                            Així mateix, totes les marques, noms comercials o
                            signes distintius de qualsevol classe que apareixen
                            en el lloc web són propietat de Funerària Ferran,
                            sense que pugui entendre's que l'ús o accés al
                            mateix atribueixi a l'usuari dret algun sobre
                            aquests.
                            <br />
                            La distribució, modificació, cessió o comunicació
                            pública dels continguts i qualsevol altre acte que
                            no hagi estat expressament autoritzat pel titular
                            dels drets d'explotació queden prohibits.
                            <br />
                            L'establiment d'un hiperenlace no implica en cap cas
                            l'existència de relacions entre Funerària Ferran i
                            el propietari del lloc web en la qual s'estableixi,
                            ni l'acceptació i aprovació per part de Funerària
                            Ferran dels seus continguts o serveis.
                            <br />
                            Funerària Ferran no es responsabilitza de l'ús que
                            cada usuari li doni als materials posats a
                            disposició en aquest lloc web ni de les actuacions
                            que realitzi sobre la base d'aquests.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            3.1 EXCLUSIÓ DE GARANTIES I DE RESPONSABILITAT EN
                            L'ACCÉS I LA UTILITZACIÓ
                        </Typography>
                        <Typography variant="body1">
                            El contingut del present lloc web és de caràcter
                            general i té una finalitat merament informativa,
                            sense que es garanteixi plenament l'accés a tots els
                            continguts, ni la seva exhaustivitat, correcció,
                            vigència o actualitat, ni la seva idoneïtat o
                            utilitat per a un objectiu específic. Funerària
                            Ferran exclou, fins on permet l'ordenament jurídic,
                            qualsevol responsabilitat pels danys i perjudicis de
                            tota naturalesa derivats de:
                            <br />
                            <ul>
                                <li>
                                    a) La impossibilitat d'accés al lloc web o
                                    la falta de veracitat, exactitud,
                                    exhaustivitat i/o actualitat dels
                                    continguts, així com l'existència de vicis i
                                    defectes de tota classe dels continguts
                                    transmesos, difosos, emmagatzemats, posats a
                                    disposició, als quals s'hagi accedit a
                                    través del lloc web o dels serveis que
                                    s'ofereixen.
                                </li>
                                <li>
                                    b) La presència de virus o d'altres elements
                                    en els continguts que puguin produir
                                    alteracions en els sistemes informàtics,
                                    documents electrònics o dades dels usuaris.
                                </li>
                                <li>
                                    c) L'incompliment de les lleis, la bona fe,
                                    l'ordre públic, els usos del trànsit i el
                                    present avís legal com a conseqüència de
                                    l'ús incorrecte del lloc web. En particular,
                                    i a manera ejemplificativo, Funerària Ferran
                                    no es fa responsable de les actuacions de
                                    tercers que vulnerin drets de propietat
                                    intel·lectual i industrial, secrets
                                    empresarials, drets a l'honor, a la
                                    intimitat personal i familiar i a la pròpia
                                    imatge, així com la normativa en matèria de
                                    competència deslleial i publicitat
                                    il·lícita.
                                </li>
                            </ul>
                            Així mateix, Funerària Ferran declina qualsevol
                            responsabilitat respecte a la informació que es
                            trobi fora d'aquesta web i no sigui gestionada
                            directament pel nostre webmaster. La funció dels
                            links que apareixen en aquesta web és exclusivament
                            la d'informar l'usuari sobre l'existència d'altres
                            fonts susceptibles d'ampliar els continguts que
                            ofereix aquest lloc web. Funerària Ferran no
                            garanteix ni es responsabilitza del funcionament o
                            accessibilitat dels llocs enllaçats; ni suggereix,
                            convida o recomana la visita a aquests, per la qual
                            cosa tampoc serà responsable del resultat obtingut.
                            Funerària Ferran no es responsabilitza de
                            l'establiment de hipervínculos per part de tercers.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            3.2 PROCEDIMENT EN CAS DE REALITZACIÓ D'ACTIVITATS
                            DE CARÀCTER IL·LÍCIT
                        </Typography>
                        <Typography variant="body1">
                            En el cas que qualsevol usuari o un tercer consideri
                            que existeixen fets o circumstàncies que revelin el
                            caràcter il·lícit de la utilització de qualsevol
                            contingut i/o de la realització de qualsevol
                            activitat en les pàgines web incloses o accessibles
                            a través del lloc web, haurà d'enviar una
                            notificació a Funerària Ferran identificant-se
                            degudament i especificant les suposades infraccions.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">3.3 PUBLICACIONS</Typography>
                        <Typography variant="body1">
                            La informació administrativa facilitada a través del
                            lloc web no substitueix la publicitat legal de les
                            lleis, normatives, plans, disposicions generals i
                            actes que hagin de ser publicats formalment als
                            diaris oficials de les administracions públiques,
                            que constitueixen l'únic instrument que dóna fe de
                            la seva autenticitat i contingut. La informació
                            disponible en aquest lloc web ha d'entendre's com
                            una guia sense propòsit de validesa legal.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">
                            4. POLÍTICA DE COOKIES
                        </Typography>
                        <Typography variant="body1">
                            <Typography variant="h6">
                                Què són les cookies?
                            </Typography>
                            Una cookie és un fitxer que es descarrega en el seu
                            ordinador en accedir a determinades pàgines web. Les
                            cookies permeten a una pàgina web, entre altres
                            coses, emmagatzemar i recuperar informació sobre
                            hàbits de navegació d'un usuari o del seu equip i,
                            depenent de la informació que continguin i de la
                            forma que utilitzi el seu equip, poden utilitzar-se
                            per a reconèixer a l'usuari.
                            <br />
                            <Typography variant="h6">
                                Per a què utilitza les cookies aquesta pàgina
                                web i quins són?
                            </Typography>
                            Aquesta pàgina web no utilitza cookies.
                            <Typography variant="h6">
                                Com puc desactivar o eliminar cookies?
                            </Typography>
                            Pot vostè permetre, bloquejar o eliminar les cookies
                            instal·lades en el seu equip mitjançant la
                            configuració de les opcions del navegador instal·lat
                            en el seu ordinador:
                            <p>Firefox</p>
                            https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                            <p>Chrome</p>
                            https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
                            <p>Explorer</p>
                            https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
                            <p>Safari</p>
                            https://support.apple.com/kb/ph5042
                            <p>Opera</p>
                            https://help.opera.com/Windows/11.50/es-ES/cookies.html
                            <p>Altres navegadors</p>
                            Consulti la documentació del navegador que tingui
                            instal·lat.
                            <br />
                            Complement d'inhabilitació per a navegadors de
                            Google Analytics
                            <br />
                            Si desitja rebutjar les cookies analítiques de
                            Google Analytics en tots els navegadors, de manera
                            que no s'enviï informació seva a Google Analytics,
                            pot descarregar un complement que realitza aquesta
                            funció des d'aquest enllaç:
                            https://tools.google.com/dlpage/gaoptout.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            5. LEGISLACIÓ APLICABLE
                        </Typography>
                        <Typography variant="body1">
                            Les condicions presents es regiran per la legislació
                            espanyola vigent.
                            <br />
                            La llengua utilitzada serà el Castellà.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            6. POLITICA DE PRIVACITAT
                        </Typography>
                        <Typography variant="body1">
                            Tractament de les dades personals dels contactes de
                            sol·licitud de comandes i correu electrònic
                            <br />
                            Funerària Ferran garanteix la confidencialitat i el
                            tractament de les dades de caràcter personal
                            facilitades pels usuaris d'acord amb la legislació
                            vigent sobre protecció de dades de caràcter personal
                            i d'acord amb tota la normativa aplicable en aquesta
                            matèria. La informació completa sobre la política de
                            privacitat es troba en aquest enllaç:
                            <RouterLink to="/politica-privacitat">
                                https://funerariaferran.com/politica-privacitat
                            </RouterLink>
                        </Typography>
                    </Box>
                </Container>
                <Footer />
            </Page>
        );
    } else {
        return (
            <Page name={t('Avís Legal')} className={classes.root}>
                <Container maxWidth="lg" className={classes.main}>
                    <Box>
                        <Typography variant="h1">{t('Avís legal')}</Typography>
                        <Box my={3} />
                        <Typography variant="h3">0. OBJETO</Typography>
                        <Typography variant="body1">
                            El presente aviso legal regula el uso y utilización
                            del sitio web funerariaferran.com, del que es
                            titular Funerària Ferran SL (en adelante, Funerària
                            Ferran).
                            <br />
                            La navegación por el sitio web de Funerària Ferran
                            le atribuye la condición de USUARIO del mismo y
                            conlleva su aceptación plena y sin reservas de todas
                            y cada una de las condiciones publicadas en este
                            aviso legal, advirtiendo de que dichas condiciones
                            podrán ser modificadas sin notificación previa por
                            parte de Funerària Ferran, en cuyo caso se procederá
                            a su publicación y aviso con la máxima antelación
                            posible.
                            <br />
                            Por ello es recomendable leer atentamente su
                            contenido en caso de desear acceder y hacer uso de
                            la información y de los servicios ofrecidos desde
                            este sitio web.
                            <br />
                            El usuario además, se obliga a hacer un uso correcto
                            del sitio web de conformidad con las leyes, la buena
                            fe, el orden público, los usos del tráfico y el
                            presente Aviso Legal, y responderá frente a
                            Funerària Ferran o frente a terceros, de
                            cualesquiera daños y perjuicios que pudieran
                            causarse como consecuencia del incumplimiento de
                            dicha obligación.
                            <br />
                            Cualquier utilización distinta a la autorizada está
                            expresamente prohibida, pudiendo Funerària Ferran
                            denegar o retirar el acceso y su uso en cualquier
                            momento.
                            <br />
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">1. IDENTIFICACIÓN</Typography>
                        <Typography variant="body1">
                            Funerària Ferran, en cumplimiento de la Ley 34/2002,
                            de 11 de julio, de Servicios de la Sociedad de la
                            Información y de Comercio Electrónico, le informa de
                            que:
                            <ul>
                                <li>
                                    Su denominación social es: Funerària Ferran
                                    SL.
                                </li>
                                <li>
                                    Su nombre comercial es: Funerària Ferran.
                                </li>
                                <li>Su CIF es: B59945840.</li>
                                <li>
                                    Su domicilio social está en: Crtra. de
                                    Ribes, 8-10 - 08600 Berga - Barcelona
                                    (España).
                                </li>
                                <li>
                                    Está inscrita en el Registro Mercantil de
                                    BARCELONA T 21773 , F 100, S 8, H B 26820.
                                </li>
                            </ul>
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">2. COMUNICACIONES</Typography>
                        <Typography variant="body1">
                            Para comunicarse con nosotros, ponemos a su
                            disposición diferentes medios de contacto que
                            detallamos a continuación:
                            <br />
                            Tfno: 938210310 - Horario de oficina y gestiones
                            administrativas: de lunes a viernes de 9h a 13h y de
                            16h a 19h; y sábados de 9h a 13h. Gestión de
                            defunciones: disponibilidad las 24h, 7 días en la
                            semana.
                            <br />
                            Email: info@funerariaferran.com.
                            <br />
                            Todas las notificaciones y comunicaciones entre los
                            usuarios y Funerària Ferran se considerarán
                            eficaces, a todos los efectos, cuando se realicen a
                            través de cualquier medio de los detallados
                            anteriormente.
                            <br />
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">
                            3. CONDICIONES DE ACCESO Y UTILIZACIÓN
                        </Typography>
                        <Typography variant="body1">
                            El sitio web y sus servicios son de acceso libre y
                            gratuito. No obstante, Funerària Ferran puede
                            condicionar la utilización de algunos de los
                            servicios ofrecidos en su web a la previa
                            cumplimentación del correspondiente formulario.
                            <br />
                            El usuario garantiza la autenticidad y actualidad de
                            todos aquellos datos que comunique a Funerària
                            Ferran y será el único responsable de las
                            manifestaciones falsas o inexactas que realice.
                            <br />
                            El usuario se compromete expresamente a hacer un uso
                            adecuado de los contenidos y servicios de Funerària
                            Ferran y a no emplearlos para, entre otros:
                            <br />
                            <ul>
                                <li>
                                    a) Difundir contenidos delictivos,
                                    violentos, pornográficos, racistas,
                                    xenófobos, ofensivos, de apología del
                                    terrorismo o, en general, contrarios a la
                                    ley o al orden público.
                                </li>
                                <li>
                                    b) Introducir en la red virus informáticos o
                                    realizar actuaciones susceptibles de
                                    alterar, estropear, interrumpir o generar
                                    errores o daños en los documentos
                                    electrónicos, datos o sistemas físicos y
                                    lógicos de Funerària Ferran o de terceras
                                    personas; así como obstaculizar el acceso de
                                    otros usuarios al sitio web y a sus
                                    servicios mediante el consumo masivo de los
                                    recursos informáticos a través de los cuales
                                    Funerària Ferran presta sus servicios.
                                </li>
                                <li>
                                    c) Intentar acceder a las cuentas de correo
                                    electrónico de otros usuarios o a áreas
                                    restringidas de los sistemas informáticos de
                                    Funerària Ferran o de terceros y, en su
                                    caso, extraer información.
                                </li>
                                <li>
                                    d) Vulnerar los derechos de propiedad
                                    intelectual o industrial, así como violar la
                                    confidencialidad de la información de
                                    Funerària Ferran o de terceros.
                                </li>
                                <li>
                                    e) Suplantar la identidad de cualquier otro
                                    usuario.
                                </li>
                                <li>
                                    f) Reproducir, copiar, distribuir, poner a
                                    disposición de, o cualquier otra forma de
                                    comunicación pública, transformar o
                                    modificar los contenidos, a menos que se
                                    cuente con la autorización del titular de
                                    los correspondientes derechos o ello resulte
                                    legalmente permitido.
                                </li>
                                <li>
                                    g) Recabar datos con finalidad publicitaria
                                    y de remitir publicidad de cualquier clase y
                                    comunicaciones con fines de venta u otras de
                                    naturaleza comercial sin que medie su previa
                                    solicitud o consentimiento.
                                </li>
                            </ul>
                            Todos los contenidos del sitio web, como textos,
                            fotografías, gráficos, imágenes, iconos, tecnología,
                            software, así como su diseño gráfico y códigos
                            fuente, constituyen una obra cuya propiedad
                            pertenece a Funerària Ferran, sin que puedan
                            entenderse cedidos al usuario ninguno de los
                            derechos de explotación sobre los mismos más allá de
                            lo estrictamente necesario para el correcto uso de
                            la web.
                            <br />
                            En definitiva, los usuarios que accedan a este sitio
                            web pueden visualizar los contenidos y efectuar, en
                            su caso, copias privadas autorizadas siempre que los
                            elementos reproducidos no sean cedidos
                            posteriormente a terceros, ni se instalen a
                            servidores conectados a redes, ni sean objeto de
                            ningún tipo de explotación.
                            <br />
                            Asimismo, todas las marcas, nombres comerciales o
                            signos distintivos de cualquier clase que aparecen
                            en el sitio web son propiedad de Funerària Ferran,
                            sin que pueda entenderse que el uso o acceso al
                            mismo atribuya al usuario derecho alguno sobre los
                            mismos.
                            <br />
                            La distribución, modificación, cesión o comunicación
                            pública de los contenidos y cualquier otro acto que
                            no haya sido expresamente autorizado por el titular
                            de los derechos de explotación quedan prohibidos.
                            <br />
                            El establecimiento de un hiperenlace no implica en
                            ningún caso la existencia de relaciones entre
                            Funerària Ferran y el propietario del sitio web en
                            la que se establezca, ni la aceptación y aprobación
                            por parte de Funerària Ferran de sus contenidos o
                            servicios.
                            <br />
                            Funerària Ferran no se responsabiliza del uso que
                            cada usuario le dé a los materiales puestos a
                            disposición en este sitio web ni de las actuaciones
                            que realice en base a los mismos.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            3.1 EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN
                            EL ACCESO Y LA UTILIZACIÓN
                        </Typography>
                        <Typography variant="body1">
                            El contenido del presente sitio web es de carácter
                            general y tiene una finalidad meramente informativa,
                            sin que se garantice plenamente el acceso a todos
                            los contenidos, ni su exhaustividad, corrección,
                            vigencia o actualidad, ni su idoneidad o utilidad
                            para un objetivo específico. Funerària Ferran
                            excluye, hasta donde permite el ordenamiento
                            jurídico, cualquier responsabilidad por los daños y
                            perjuicios de toda naturaleza derivados de:
                            <br />
                            <ul>
                                <li>
                                    a) La imposibilidad de acceso al sitio web o
                                    la falta de veracidad, exactitud,
                                    exhaustividad y/o actualidad de los
                                    contenidos, así como la existencia de vicios
                                    y defectos de toda clase de los contenidos
                                    transmitidos, difundidos, almacenados,
                                    puestos a disposición, a los que se haya
                                    accedido a través del sitio web o de los
                                    servicios que se ofrecen.
                                </li>
                                <li>
                                    b) La presencia de virus o de otros
                                    elementos en los contenidos que puedan
                                    producir alteraciones en los sistemas
                                    informáticos, documentos electrónicos o
                                    datos de los usuarios.
                                </li>
                                <li>
                                    c) El incumplimiento de las leyes, la buena
                                    fe, el orden público, los usos del tráfico y
                                    el presente aviso legal como consecuencia
                                    del uso incorrecto del sitio web. En
                                    particular, y a modo ejemplificativo,
                                    Funerària Ferran no se hace responsable de
                                    las actuaciones de terceros que vulneren
                                    derechos de propiedad intelectual e
                                    industrial, secretos empresariales, derechos
                                    al honor, a la intimidad personal y familiar
                                    y a la propia imagen, así como la normativa
                                    en materia de competencia desleal y
                                    publicidad ilícita.
                                </li>
                            </ul>
                            Asimismo, Funerària Ferran declina cualquier
                            responsabilidad respecto a la información que se
                            halle fuera de esta web y no sea gestionada
                            directamente por nuestro webmaster. La función de
                            los links que aparecen en esta web es exclusivamente
                            la de informar al usuario sobre la existencia de
                            otras fuentes susceptibles de ampliar los contenidos
                            que ofrece este sitio web. Funerària Ferran no
                            garantiza ni se responsabiliza del funcionamiento o
                            accesibilidad de los sitios enlazados; ni sugiere,
                            invita o recomienda la visita a los mismos, por lo
                            que tampoco será responsable del resultado obtenido.
                            Funerària Ferran no se responsabiliza del
                            establecimiento de hipervínculos por parte de
                            terceros.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            3.2 PROCEDIMIENTO EN CASO DE REALIZACIÓN DE
                            ACTIVIDADES DE CARÁCTER ILÍCITO
                        </Typography>
                        <Typography variant="body1">
                            En el caso de que cualquier usuario o un tercero
                            considere que existen hechos o circunstancias que
                            revelen el carácter ilícito de la utilización de
                            cualquier contenido y/o de la realización de
                            cualquier actividad en las páginas web incluidas o
                            accesibles a través del sitio web, deberá enviar una
                            notificación a Funerària Ferran identificándose
                            debidamente y especificando las supuestas
                            infracciones.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">3.3 PUBLICACIONES</Typography>
                        <Typography variant="body1">
                            La información administrativa facilitada a través
                            del sitio web no sustituye la publicidad legal de
                            las leyes, normativas, planes, disposiciones
                            generales y actos que tengan que ser publicados
                            formalmente a los diarios oficiales de las
                            administraciones públicas, que constituyen el único
                            instrumento que da fe de su autenticidad y
                            contenido. La información disponible en este sitio
                            web debe entenderse como una guía sin propósito de
                            validez legal.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h3">
                            4. POLÍTICA DE COOKIES
                        </Typography>
                        <Typography variant="body1">
                            <Typography variant="h6">
                                ¿Qué son las cookies?
                            </Typography>
                            Una cookie es un fichero que se descarga en su
                            ordenador al acceder a determinadas páginas web. Las
                            cookies permiten a una página web, entre otras
                            cosas, almacenar y recuperar información sobre
                            hábitos de navegación de un usuario o de su equipo
                            y, dependiendo de la información que contengan y de
                            la forma que utilice su equipo, pueden utilizarse
                            para reconocer al usuario.
                            <br />
                            <Typography variant="h6">
                                ¿Para que utiliza las cookies esta página web y
                                cuales son?
                            </Typography>
                            Esta página web no utiliza cookies.
                            <Typography variant="h6">
                                ¿Cómo puedo desactivar o eliminar cookies?
                            </Typography>
                            Puede usted permitir, bloquear o eliminar las
                            cookies instaladas en su equipo mediante la
                            configuración de las opciones del navegador
                            instalado en su ordenador:
                            <p>Firefox</p>
                            https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                            <p>Chrome</p>
                            https://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
                            <p>Explorer</p>
                            https://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
                            <p>Safari</p>
                            https://support.apple.com/kb/ph5042
                            <p>Opera</p>
                            https://help.opera.com/Windows/11.50/es-ES/cookies.html
                            <p>Otros navegadores</p>
                            Consulte la documentación del navegador que tenga
                            instalado.
                            <br />
                            Complemento de inhabilitación para navegadores de
                            Google Analytics
                            <br />
                            Si desea rechazar las cookies analíticas de Google
                            Analytics en todos los navegadores, de forma que no
                            se envíe información suya a Google Analytics, puede
                            descargar un complemento que realiza esta función
                            desde este enlace:
                            https://tools.google.com/dlpage/gaoptout.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            5. LEGISLACIÓN APLICABLE
                        </Typography>
                        <Typography variant="body1">
                            Las condiciones presentes se regirán por la
                            legislación española vigente.
                            <br />
                            La lengua utilizada será el Castellano.
                        </Typography>
                        <Box my={3} />
                        <Typography variant="h4">
                            6. POLITICA DE PRIVACIDAD
                        </Typography>
                        <Typography variant="body1">
                            Tractamento de los datos personales de los contactos
                            de solicitud de pedidos y correo electrónico.
                            <br />
                            Qui és el responsable del tractament de les seves
                            dades?
                            <br />
                            Funerària Ferran garantiza la confidencialidad y el
                            tratamiento de los datos de carácter personal
                            facilitadas por los usuarios de acuerdo con la
                            legislación vigente sobre protección de datos de
                            carácter personal y de acuerdo con toda la normativa
                            aplicable en esta materia. La información completa
                            sobre la política de privacidad se encuentra en este
                            enlace:{' '}
                            <RouterLink to="/politica-privacitat">
                                https://funerariaferran.com/politica-privacidad
                            </RouterLink>
                        </Typography>
                    </Box>
                </Container>
                <Footer />
            </Page>
        );
    }
};

export default AvisLegal;
