import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { Grow, IconButton, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
import { Close } from "@material-ui/icons";
import { getUser } from "./database/API";

function App() {
    const [user, setUser] = useState("");
    const routing = useRoutes(routes(user));
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useEffect(() => {
        const loggedInUser = localStorage.getItem("isLoggedIn");
        if (loggedInUser !== "false") {
            const { message } = getUser();
            if (message === "401") {
                localStorage.removeItem("user");
                localStorage.setItem("isLoggedIn", false);
                setUser("");
            } else {
                const foundUser = JSON.parse(loggedInUser);
                setUser(foundUser);
            }
        } else {
            setUser("");
        }
    }, [routing]);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                TransitionComponent={Grow}
                ref={notistackRef}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <Close style={{ color: "white" }} />
                    </IconButton>
                )}
            >
                {routing}
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
