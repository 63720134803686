const TRANSLATIONS_ES = {
	"Els Ferran": "Els Ferran",
	Inici: "Inicio",
	Necrològiques: "Necrológicas",
	Informació: "Información",
	Contacte: "Contacto",
	Tanatoris: "Tanatorios",
	anys: "años",
	"Sales de vetlla": "Salas de velatorio",
	Sala: "Sala",
	Cerimònia: "Ceremonia",
	Dia: "Día",
	" a les ": " a las ",
	Destí: "Destino",
	"Ofrena floral": "Ofrenda floral",
	"Notes de condol": "Notas de pésame",
	"S'ha copiat l'enllaç per compartir": "Se ha copiado el enlace para compartir",
	"No s'ha trobat la pàgina": "No se ha encontrado la página",
	"El camp només pot contenir dígits": "El campo solo puede contener dígitos",
	"El nom és obligatòri": "El nombre es obligatorio",
	"Ha de ser un email vàlid": "Tiene que ser un email válido",
	"L'email és obligatòri": "El email es obligatorio",
	"Telèfon no vàlid": "Teléfono no válido",
	"Telèfon massa curt": "Teléfono demasiado corto",
	"El telèfon és obligatòri": "El teléfono es obligatorio",
	"L'adreça és obligatòria": "La dirección es obligatoria",
	"La població és obligatòria": "La población es obligatoria",
	"El codi postal és obligatòri": "El código postal es obligatorio",
	"La província és obligatòria": "La provincia es obligatoria",
	"El DNI és obligatòri": "El DNI es obligatorio",
	"El país és obligatòri": "El país es obligatorio",
	"El de l'empresa és obligatòri": "El de la empresa es obligatorio",
	"Has superat el límit de la nota": "Has superado el límite de la nota",
	Categoria: "Categoría",
	Mida: "Medida",
	"Preu (IVA Inclòs)": "Precio (IVA Incluido)",
	"Nota de condol": "Nota de pésame",
	"Afegir cinta": "Añadir cinta",
	"Escrit cinta": "Escrito cinta",
	"Les teves dades": "Tus datos",
	"Nom i cognoms": "Nombre y apellidos",
	"Necessito factura": "Necesito factura",
	Telèfon: "Teléfono",
	Telèfons: "Teléfonos",
	"Nom empresa": "Nombre empresa",
	Adreça: "Dirección",
	"Codi postal": "Código postal",
	Població: "Población",
	Província: "Provincia",
	Pais: "País",
	"Enviar comanda": "Enviar pedido",
	"Confirma les dades": "Confirma los datos",
	Difunt: "Difunto",
	Preu: "Precio",
	Escrit: "Escrito",
	"IVA Inclòs": "IVA Incluido",
	"Confirmar dades i comprar": "Confirmar datos y comprar",
	"Compra fallada": "Compra fallida",
	"Alguna cosa no ha anat bé. Torna-ho a intentar més tard.": "Algo no ha ido bien. Vuélvelo a intentar más tarde.",
	"Disculpa les molèsties.": "Disculpa las molestias.",
	"Compra efectuada amb èxit": "Compra efectuada con éxito",
	"Moltes gràcies": "Muchas gracias",
	"Resum de la comanda": "Resumen del pedido",
	"Dades de facturació": "Datos de facturación",
	"Catàleg floral": "Catálogo floral",
	"Si teniu intenció d'adquirir flors per al difunt, ho podeu fer des d'aquí.":
		"Si tenéis intención de adquirir flores para el difunto, lo podéis hacer desde aquí.",
	"Rebeu el nostre més sincer condol per tan sentida pèrdua.": "Recibid nuestro más sincero pésame por tan sentida pérdida.",
	"Estem al vostre costat en tan dolorosos moments.": "Estamos a vuestro lado en tan dolorosos momentos.",
	"Mai t'oblidarem, sempre estaràs amb nosaltres.": "Nunca te olvidaremos, siempre estarás con nosotros.",
	"La direcció i empleats de (...) ens unim al dol de la família.": "La dirección y empleados de (...) nos unimos al luto de la familia.",
	"Els socis i la Junta Directiva de (...) expressem el nostre més sincer condol a la família per tan sentida pèrdua.":
		"Los socios y la Junta Directiva de (...) expresamos nuestro más sincero pésame a la familia por tan sentida pérdida.",
	"Els companys de (...) mai t'oblidarem, sempre estaràs amb nosaltres.": "Los compañeros de (...) nunca te olvidaremos, siempre estarás con nosotros.",
	"Les notes de condol seran revisades pel nostre personal abans de ser lliurades als familiars del difunt.":
		"Las notas de pésame serán revisadas por nuestro personal antes de ser libradas a los familiares del difunto.",
	"El missatge és obligatòri": "El mensaje es obligatorio",
	"Has d'acceptar la Política de Privacitat per continuar": "Tienes que aceptar la Política de Privacidad para continuar",
	"Models predefinits": "Modelos predefinidos",
	Nota: "Nota",
	"Opció de Nota de condol en una rosa. 10 €": "Opción de Nota de pésame en una rosa. 10 €",
	"He llegit i accepto la": "He leído y acepto la",
	"Política de Privacitat": "Política de Privacidad",
	"POLÍTICA DE PRIVACITAT": "POLÍTICA DE PRIVACIDAD",
	Missatge: "Mensaje",
	"Disseny i programació": "Diseño y programación",
	"AVÍS LEGAL": "AVISO LEGAL",
	"Telèfon de contacte": "Teléfono de contacto",
	Oficines: "Oficinas",
	"de dilluns a divendres de 9h a 13h i de 16h a 19h; i dissabtes de 9h a 13h.": "de lunes a viernes de 9h a 13h y de 16h a 19h; y sábados de 9h a 13h.",
	"Horari d'oficina i gestions administratives": "Horario de oficina y gestiones administrativas",
	"Horari d'oficina": "Horario de oficina",
	"Gestió de defuncions": "Gestión de defunciones",
	"disponibilitat les 24h, 7 dies a la setmana.": "disponibilidad las 24h, 7 días de la semana.",
	"En cas d'una defunció cal seguir diversos passos.": "En caso de una defunción hay que seguir varios pasos.",
	"No hi ha necrològiques": "No hay necrológicas",
	"La Funerària Ferran treballa amb": "La Funeraria Ferran trabaja con",
	"Una sala de vetlla": "Una sala de velatorio",
	"Dues sales de vetlla": "Dos salas de velatorio",
	"Tres sales de vetlla": "Tres salas de velatorio",
	"Com arribar": "Como llegar",
	"Avís legal": "Aviso legal",
	"TERMES I CONDICIONS": "TERMINOS Y CONDICIONES",
	"He llegit i accepto els ": "He lído y acepto los ",
	"Termes i condicions": "Terminos y condiciones",
	Cercar: "Buscar",
	"Necrològiques recents": "Necrológicas recientes",
	"La necrològica ja no està disponible": "La necrológica ya no está disponible",
	"Per a més informació": "Para más información",
	"Veure necrològica": "Ver necrológica",
	"Veure necrològiques recents": "Ver necrológicas recientes",
	"Formem part de": "Formamos parte de",
};

export default TRANSLATIONS_ES;
