import React, { useEffect, useState } from "react";
import { Box, Button, Container, Fade, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Element } from "react-scroll";
import { getNecrologiques } from "../../../../database/API";
import Necrologica from "../../../../components/Necrologica";
import ScrollAnimation from "react-animate-on-scroll";
import NecrologicaSkeleton from "../../../../components/Skeletons/NecrologicaSkeleton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const Necrologiques = (props) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { setLoadingNecro } = props;
	const [difunts, setDifunts] = useState();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		const get = async () => {
			const { difunts } = await getNecrologiques(true);
			setDifunts(difunts);
			setLoadingNecro(false);
			setLoading(false);
		};

		get();
	}, [setLoadingNecro]);

	return (
		<Element name="necrologiques" className={clsx(classes.main, matches ? classes.desktop : classes.mobile)}>
			<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
				<Container maxWidth="lg">
					<Typography variant="h2" className={classes.titol}>
						{t("Necrològiques")}
					</Typography>
					{!loading ? (
						<Fade in={!loading}>
							{difunts.length > 0 ? (
								<Grid container spacing={10}>
									{difunts?.map((data) => {
										return <Necrologica key={data.id} data={data} loading={loading} />;
									})}
								</Grid>
							) : (
								<Box textAlign="center">
									<Typography variant="body1" className={classes.noNecro}>
										{t("No hi ha necrològiques")}
									</Typography>
								</Box>
							)}
						</Fade>
					) : (
						<NecrologicaSkeleton />
					)}
					<Box mt={5} textAlign="left">
						<Typography onClick={() => navigate("/necrologiques-recents")} style={{ cursor: "pointer", color: "#d0cdbe" }}>
							{t("Veure necrològiques recents")}
						</Typography>
					</Box>
				</Container>
			</ScrollAnimation>
		</Element>
	);
};

export default Necrologiques;

const useStyles = makeStyles(() => ({
	main: {
		display: "flex",
		flexDirection: "column",
		backgroundImage: `url("${process.env.PUBLIC_URL}/images/fons-tanatoris.jpg")`,
		justifyContent: "center",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "right",
		backgroundPositionY: "bottom",
	},
	desktop: {
		height: "100vh",
	},
	mobile: {
		marginTop: 50,
		marginBottom: 50,
	},
	titol: {
		paddingBottom: 40,
	},
	noNecro: {
		opacity: 0.6,
		paddingTop: 50,
		paddingBottom: 50,
	},

	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
}));
