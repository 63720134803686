import React from "react";
import { Box, Container, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
	main: {
		marginTop: 30,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		backgroundImage: `url("${process.env.PUBLIC_URL}/images/fons-portada.jpg")`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
}));

const Video = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();

	return (
		<Element className={classes.main}>
			<Container maxWidth="lg">
				<Box my={30} borderRadius={45} overflow={"hidden"} position={"relative"}>
					<iframe
						width="100%"
						height={matches ? "610" : "300"}
						src="https://www.youtube-nocookie.com/embed/ddAMAAI9cWs?controls=0"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</Box>
			</Container>
		</Element>
	);
};

export default Video;
