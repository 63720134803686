import React, { useEffect } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import OrdersTable from './Table/OrdersTable';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const OrdersAdmin = () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page className={classes.root} title={'Comandes'}>
            <Container maxWidth={false} className={classes.container}>
                <OrdersTable />
            </Container>
        </Page>
    );
};

export default OrdersAdmin;
