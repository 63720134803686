import React, { useState } from "react";

export const Info = React.createContext();

export const DifuntContext = ({ children }) => {
    const [difunt, setDifunt] = useState(null);

    return (
        <Info.Provider value={{ difunt, setDifunt }}>{children}</Info.Provider>
    );
};
