import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    over: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
}));

const Loading = () => {
    const classes = useStyles();
    return (
        <Box className={classes.over}>
            <CircularProgress color="primary" />
        </Box>
    );
};

export default Loading;
