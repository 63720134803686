import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    createMuiTheme,
    Fade,
    makeStyles,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { deleteEslogan, getEslogans } from "../../../../database/API";
import { MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useSnackbar } from "notistack";
import Columns from "./EslogansColumns";
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    info: {
        marginBottom: 20,
    },
    container: {
        paddingTop: 40,
    },
    text: {
        alignSelf: "flex-end",
        padding: 4,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const EslogansAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [eslogans, setEslogans] = useState();
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const columns = Columns();

    useEffect(() => {
        const get = async () => {
            const { eslogans } = await getEslogans();
            setEslogans(eslogans);
            console.log(eslogans);
            setLoading(false);
        };

        get();
    }, []);

    const options = {
        filterType: "checkbox",

        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                let message = await deleteEslogan(eslogans[item.dataIndex].id);
                enqueueSnackbar(message, {
                    variant: "success",
                });
                console.log(message);
            });
        },
        textLabels: {
            body: {
                noMatch: "No hi ha eslogans",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) =>
                    `${"Ordenat per"} ${column.label}`,
            },
            pagination: {
                next: "Següent pàgina",
                previous: "Pàgina anterior",
                rowsPerPage: "Files per pàgina:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Cercar",
                downloadCsv: "Descarregar CSV",
                print: "Imprimir",
                viewColumns: "Veure columnes",
                filterTable: "Filtrar taula",
            },
            filter: {
                title: "FILTRES",
                reset: "resetejar",
                all: "Tots",
            },
            viewColumns: {
                title: "Mostrar columnes",
            },
            selectedRows: {
                text: "Fila/es eliminades",
                delete: "Eliminar",
            },
        },
    };

    return (
        <Box pt={2} spacing={3}>
            {!loading ? (
                <Fade in={!loading}>
                    <div>
                        <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                title={
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="h5" component="h5">
                                            Eslogans
                                        </Typography>
                                        <Tooltip title="Crear eslogan">
                                            <IconButton
                                                className={classes.addButton}
                                                onClick={() =>
                                                    navigate(
                                                        "/admin/eslogans/nou"
                                                    )
                                                }
                                            >
                                                <Add />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                }
                                data={eslogans}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                </Fade>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
};

export default EslogansAdmin;
