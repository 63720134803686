import React from "react";
import { Box, Container, makeStyles, TextField, Typography, Button, Grid, useMediaQuery } from "@material-ui/core";
import { Element } from "react-scroll";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { createContacte } from "../../../../database/API";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
	main: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		justifyContent: "center",
	},
	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
}));

const Input = withStyles((theme) => ({
	root: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderRadius: 30,
				borderColor: theme.palette.background.black,
			},
			"&.Mui-focused fieldset": {
				borderRadius: 30,
			},
		},
		"& .MuiOutlinedInput-input:-webkit-autofill": {
			borderRadius: 30,
		},
	},
}))(TextField);

const Contacte = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const enviar = async (values, actions) => {
		console.log(values);
		const message = await createContacte(values);
		enqueueSnackbar(message, {
			variant: "success",
		});
		actions.setSubmitting(false);
		actions.resetForm({});
	};

	return (
		<Element
			name="contacte"
			className={classes.main}
			style={{
				height: matches ? "100vh" : "",
			}}
		>
			<Container maxWidth="lg">
				<Typography variant="h2">{t("Contacte")}</Typography>
				<Grid
					container
					spacing={4}
					style={{
						marginTop: matches ? 0 : 40,
						marginBottom: matches ? 0 : 40,
					}}
				>
					<Grid item md={8} sm={12} xs={12}>
						<Formik
							initialValues={{
								nom: "",
								email: "",
								telefon: "",
								missatge: "",
								accepta: false,
							}}
							validationSchema={Yup.object().shape({
								nom: Yup.string().max(255).required(t("El nom és obligatòri")),
								email: Yup.string().email(t("Ha de ser un email vàlid")).max(255).required(t("L'email és obligatòri")),
								missatge: Yup.string().required(t("El missatge és obligatòri")),
								accepta: Yup.bool().oneOf([true], t("Has d'acceptar la Política de Privacitat per continuar")),
							})}
							onSubmit={(values, actions) => {
								enviar(values, actions);
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, resetForm, isSubmitting, setFieldValue, touched, values }) => (
								<form onSubmit={handleSubmit}>
									<Input
										error={Boolean(touched.nom && errors.nom)}
										fullWidth
										helperText={touched.nom && errors.nom}
										label={t("Nom i cognoms")}
										margin="normal"
										name="nom"
										className={classes.input}
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.nom}
										variant="outlined"
									/>
									<Input
										error={Boolean(touched.email && errors.email)}
										fullWidth
										helperText={touched.email && errors.email}
										label="E-mail"
										margin="normal"
										name="email"
										className={classes.input}
										onBlur={handleBlur}
										onChange={handleChange}
										type="email"
										value={values.email}
										variant="outlined"
									/>
									<Input
										error={Boolean(touched.telefon && errors.telefon)}
										fullWidth
										helperText={touched.telefon && errors.telefon}
										label="Telèfon"
										margin="normal"
										name="telefon"
										className={classes.input}
										onBlur={handleBlur}
										onChange={handleChange}
										type="telefon"
										value={values.telefon}
										variant="outlined"
									/>
									<Input
										error={Boolean(touched.missatge && errors.missatge)}
										fullWidth
										helperText={touched.missatge && errors.missatge}
										label={t("Missatge")}
										margin="normal"
										name="missatge"
										className={classes.input}
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										multiline
										value={values.missatge}
										variant="outlined"
										rows={6}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={values.accepta}
												onChange={(e) => {
													setFieldValue("accepta", e.target.checked);
												}}
												name="accepta"
											/>
										}
										label={
											<Box display={"flex"}>
												<Typography>{t("He llegit i accepto la")} </Typography>{" "}
												<div style={{ paddingLeft: 5 }} onClick={() => navigate("/politica-privacitat")}>
													{t("Política de Privacitat")}
												</div>
											</Box>
										}
									/>
									<ErrorMessage name="accepta">
										{(msg) => (
											<Typography variant="body1" style={{ color: "red" }}>
												{msg}
											</Typography>
										)}
									</ErrorMessage>
									<Box my={2}>
										<Button color="primary" disabled={isSubmitting} className={classes.button} type="submit" variant="contained">
											{"Enviar"}
										</Button>
									</Box>
								</form>
							)}
						</Formik>
					</Grid>
					<Grid item md={4} sm={12} xs={12}>
						<Box mt={2} p={2} style={{ border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: 20 }}>
							<Box pb={3}>
								<Typography variant="body1">
									<strong>{t("Telèfons")}:</strong> 93 821 03 10 | 669 45 51 59
								</Typography>
							</Box>
							<Box pb={3}>
								<Typography variant="body1">
									<strong>{t("Oficines")}:</strong> Ctra. de Ribes, 8-10 | 08600 Berga.
								</Typography>
							</Box>

							<Box pb={3}>
								<Typography variant="body1">
									<strong>{t("Horari d'oficina")}:</strong> {t("de dilluns a divendres de 9h a 13h i de 16h a 19h; i dissabtes de 9h a 13h.")}
								</Typography>
							</Box>
							<Box pb={3}>
								<Typography variant="body1">
									<strong>{t("Gestió de defuncions")}:</strong> {t("disponibilitat les 24h, 7 dies a la setmana.")}
								</Typography>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Element>
	);
};

export default Contacte;
