import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
	"@global": {
		".MuiSkeleton-wave": {
			borderRadius: 20,
		},
	},
}));

const NecrologicaSkeleton = ({ list }) => {
	useStyles();

	return (
		<Grid container spacing={10}>
			{list ? (
				<>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={150}
						/>
					</Grid>
				</>
			) : (
				<>
					<Grid item md={4} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={200}
						/>
					</Grid>
					<Grid item md={4} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={200}
						/>
					</Grid>
					<Grid item md={4} sm={12} xs={12}>
						<Skeleton
							animation="wave"
							variant="rect"
							height={200}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default NecrologicaSkeleton;
