import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Footer from '../views/public/home/elements/Footer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: 150,
        paddingBottom: 150,
        height: '80vh',
    },
    container: {
        paddingBottom: 100,
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box>
            <Container maxWidth="lg" className={classes.main}>
                <Box>
                    <Typography variant="body1">Error 404</Typography>
                    <Typography variant="h1">
                        {t("No s'ha trobat la pàgina")}
                    </Typography>
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default NotFound;
