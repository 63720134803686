import { Box, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 100,
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/degradat-marro-blanc.png")`,
        backgroundSize: "cover",
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    rootInv: {
        paddingTop: 100,
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/degradat-blanc-marro.png")`,
        backgroundSize: "cover",
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
}));
const Degradat = (props) => {
    const classes = useStyles();
    const { type } = props;
    return (
        <Box className={type === "up" ? classes.root : classes.rootInv}></Box>
    );
};

export default Degradat;
