import { makeStyles, SvgIcon } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Flor } from '../theme/icons/flor.svg';
import { ReactComponent as Notes } from '../theme/icons/notes.svg';
import { ReactComponent as Info } from '../theme/icons/info.svg';

const useStyles = makeStyles((theme) => ({
    icon: { padding: 5, fill: '#424242' },
}));

const Icona = (props) => {
    const classes = useStyles();
    const { type } = props;
    return (
        <SvgIcon
            className={classes.icon}
            component={type === 'flor' ? Flor : type === 'notes' ? Notes : Info}
        />
    );
};

export default Icona;
