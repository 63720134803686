import React, { useEffect, useState } from "react";

export const Auth = React.createContext();

export const UserContext = ({ children }) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        setUser(localStorage.getItem("user"));
    }, [user]);
    return <Auth.Provider value={{ user }}>{children}</Auth.Provider>;
};
