import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    makeStyles,
} from '@material-ui/core';
import {
    CreditCard,
    File,
    Home,
    Info,
    Mail,
    Settings,
    Users,
} from 'react-feather';
import NavItem from './NavItem';
import { NotesOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
        marginBottom: 10,
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user')) || '';
    const items = [
        {
            href: '/admin/difunts',
            icon: Users,
            title: 'Necrològiques',
        },
        {
            href: '/admin/flors',
            icon: 'flor',
            title: 'Flors',
        },
        {
            href: '/admin/comandes',
            icon: File,
            title: 'Comandes',
        },

        {
            href: '/admin/notes',
            icon: NotesOutlined,
            title: 'Notes de condol',
        },
        {
            href: '/admin/tanatoris',
            icon: Home,
            title: 'Tanatoris',
        },
        {
            href: '/admin/informacio',
            icon: Info,
            title: 'Informació',
        },
        {
            href: '/admin/contacte',
            icon: Mail,
            title: 'Contactes',
        },
        {
            href: '/admin/configuracio',
            icon: Settings,
            title: 'Configuració',
        },
        {
            href: '/admin/pagament',
            icon: CreditCard,
            title: 'Pagaments targeta',
        },
    ];

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Typography color="textSecondary" variant="body2">
                    {user.email}
                </Typography>
            </Box>
            <Divider />
            <Box p={2}>
                <List>
                    {items?.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            onClose={onMobileClose}
                        />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
};

export default NavBar;
