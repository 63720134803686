import { Box, useMediaQuery } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import moment from "moment";
import * as Scroll from "react-scroll";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	anys: {
		fontWeight: 800,
		color: theme.palette.primary.main,
	},
	imatge: {
		borderRadius: 20,
		width: "100%",
		height: 150,
	},
	titol: {
		fontSize: 30,
	},
	titolM: {
		fontSize: 24,
	},
	header: {
		cursor: "pointer",
		// marginTop: 55
	},
}));

const DifuntHeader = (props) => {
	const classes = useStyles();
	const { difunt } = props;
	const matches = useMediaQuery("(min-width:960px)");
	const { t } = useTranslation();

	return (
		difunt && (
			<Box
				onClick={() =>
					Scroll.animateScroll.scrollTo(0, { duration: 500 })
				}
				className={classes.header}
			>
				<Box pb={matches ? 2 : 0} pt={matches ? 0 : 1}>
					<Typography
						variant="h2"
						className={matches ? classes.titol : classes.titolM}
					>
						{difunt?.nom} {difunt?.cognoms}
					</Typography>
				</Box>

				<Typography className={classes.anys}>
					{difunt?.data_naixement &&
						moment(difunt?.data_naixement).format("DD/MM/YYYY") +
							" - "}
					{moment(difunt?.data_defuncio).format("DD/MM/YYYY")}
				</Typography>
				{difunt?.data_naixement && (
					<Typography>
						(
						{moment(difunt?.data_defuncio).diff(
							moment(difunt?.data_naixement),
							"years"
						)}{" "}
						{t("anys")})
					</Typography>
				)}
			</Box>
		)
	);
};

export default DifuntHeader;
