import * as React from "react";
import { Box, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

export default function CustomTextField({ errors, label, name, type, register, size, light, adornment, endAdornment, mt, ...rest }) {
	const classes = useStyles();
	return (
		<Box mt={mt} style={{ width: "100%" }}>
			<TextField
				error={Boolean(errors)}
				helperText={errors?.message}
				fullWidth
				label={label}
				name={name}
				type={type}
				size={size === "small" ? "small" : "medium"}
				InputProps={{
					classes:
						size === "small"
							? {
									input: classes.resize,
							  }
							: {},
					startAdornment: adornment,
					endAdornment: endAdornment,
				}}
				{...register(name)}
				{...rest}
			/>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	resize: {
		fontSize: "14px !important",
	},
}));
