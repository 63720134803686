import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
} from "@material-ui/core";
import Page from "../../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import Title from "../../../../components/Title";
import { getFloristeria, updateFloristeria } from "../../../../database/API";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    container: {
        paddingTop: 47,
    },
    "@global": {
        html: {
            width: "100%",
            height: "100%",
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
}));

const FloristeriaView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { key } = useParams();
    const [floristeria, setFloristeria] = useState();

    useEffect(() => {
        console.log(key);
        const get = async () => {
            const { floristeria } = await getFloristeria(key);
            console.log(floristeria);
            setFloristeria(floristeria);
        };
        get();
    }, [key]);

    const crear = async (values) => {
        console.log(values);
        const message = await updateFloristeria(values, key);
        enqueueSnackbar(message, {
            variant: "success",
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={"Editar floristeria"}>
            <Container maxWidth={false} className={classes.container}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        nom: floristeria?.nom,
                    }}
                    validationSchema={Yup.object().shape({
                        nom: Yup.string().required("El nom és obligatòri"),
                    })}
                    onSubmit={(values) => {
                        crear(values);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mb={3}>
                                <Title
                                    title={"Editar floristeria"}
                                    subtitle={
                                        "Entra les dades per guardar una nova floristeria de flor"
                                    }
                                />
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.nom && errors.nom
                                        )}
                                        fullWidth
                                        helperText={touched.nom && errors.nom}
                                        label="Nom"
                                        margin="normal"
                                        name="nom"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        variant="outlined"
                                        value={values.nom}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    {"Guardar"}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </Page>
    );
};

export default FloristeriaView;
