import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import moment from 'moment/min/moment-with-locales';
import TRANSLATIONS_CA from './translations/ca';
import TRANSLATIONS_ES from './translations/es';

const resources = {
    ca: {
        translation: TRANSLATIONS_CA,
    },
    es: {
        translation: TRANSLATIONS_ES,
    },
};

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ca',
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
i18n.moment = moment;
i18n.moment.locale(i18n.language);
export default i18n;
