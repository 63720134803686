import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Page from '../../../components/Page';
import { Container } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import Footer from '../home/elements/Footer';
import { Cancel } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '80vh',
    },

    titol: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            color: theme.palette.error.main,
        },
        '& svg': {
            fill: theme.palette.error.main,
        },
    },
}));

const UrlKO = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page name="Compra efectuada" className={classes.root}>
            <Container maxWidth="lg" className={classes.main}>
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Box className={classes.titol}>
                        <Cancel />
                        <Typography variant="h2">
                            {t('Compra fallada')}
                        </Typography>
                    </Box>
                    <Typography className={classes.titol}>
                        {t(
                            'Alguna cosa no ha anat bé. Torna-ho a intentar més tard.'
                        )}
                        {t('Disculpa les molèsties.')}
                    </Typography>
                </ScrollAnimation>
            </Container>
            <Footer />
        </Page>
    );
};

export default UrlKO;
