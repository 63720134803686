import { makeStyles } from "@material-ui/core";

const navBarStyle = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
	},
	wrapper: {
		display: "flex",
		flex: "1",
		overflow: "hidden",
		paddingTop: 100,
	},
	contentContainer: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	},
	content: {
		flex: "1 1 auto",
		height: "100%",
	},
	navBar: {
		backgroundColor: "transparent",
		justifyContent: "flex-start",
		alignContent: "flex-start",
		transition: "0.5s",
	},
	navBarDifunt: {
		background: "linear-gradient(to top, #fff0, #f7f6f4 20%)",
		backgroundColor: "transparent",
		justifyContent: "flex-start",
		alignContent: "flex-start",
		paddingBottom: 40,
		// transition: "0.5s",
		"-webkit-transition": "transform 500ms",
		transition: "background-image 0.5s",
	},
	toolbar: {
		alignItems: "flex-start",
	},
	item: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		color: theme.palette.text.primary,
		justifyContent: "flex-start",
		letterSpacing: 0,
		textTransform: "none",
		width: "100%",
		cursor: "pointer",
	},

	buttonMobile: {
		color: theme.palette.text.primary,
		justifyContent: "center",
		letterSpacing: 0,
		textTransform: "none",
		width: "100%",
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	title: {
		marginRight: "auto",
		lineHeight: 1.3,
	},
	titleMobile: {
		fontSize: 25,
	},
	active: {
		color: theme.palette.primary.main,
		"& $title": {
			fontWeight: theme.typography.fontWeightMedium,
		},
		"& $icon": {
			color: theme.palette.primary.main,
		},
	},
	logo: {
		// transition: "0.5s",
		"-webkit-transition": "transform 500ms",
		transition: "all 500ms",
		opacity: 1,
		position: "absolute",
		left: 0,
		right: 0,
		textAlign: "center",
		width: "65%",
		margin: "auto",
	},
	hide: {
		transition: "0.5s ease-in-out",
		opacity: 0,
		transform: "scale(0.8)",
	},
	itemMobile: {
		display: "flex",
		padding: 15,
	},
	buttonDrawer: {
		backgroundColor: "#fffffff0",
	},
	margin: {
		margin: theme.spacing(1),
	},
	menuItem: {
		fontFamily: "Red Hat Text",
	},
	link: {
		zIndex: 100,
	},
}));

export default navBarStyle;
