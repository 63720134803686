import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    TextField,
    Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateTanatori, getTanatori } from '../../../database/API';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import { CircularProgress } from '@material-ui/core';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 47,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const TanatoriView = () => {
    const classes = useStyles();
    const { key } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [tanatori, setTanatori] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { tanatori } = await getTanatori(key);
            setTanatori(tanatori);
            setLoading(false);
        };
        get();
    }, [key]);

    const update = async (values) => {
        const message = await updateTanatori(values, key);
        enqueueSnackbar(message, {
            variant: 'success',
        });
        navigate(-1, { state: values });
    };

    return (
        <Page className={classes.root} title={'Editar tanatori'}>
            <Container maxWidth={false} className={classes.container}>
                <Box mb={3}>
                    <Title title={'Editar tanatori'} />
                </Box>
                {!loading ? (
                    <Fade in={!loading}>
                        <div>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    nom: tanatori?.nom,
                                    sales: tanatori?.sales,
                                    carrer: tanatori?.carrer,
                                    poblacio: tanatori?.poblacio,
                                    codi_postal: tanatori?.codi_postal,
                                    provincia: tanatori?.provincia,
                                    link: tanatori?.link,
                                }}
                                validationSchema={Yup.object().shape({
                                    nom: Yup.string()
                                        .max(255)
                                        .required('El nom és obligatòri'),
                                    sales: Yup.number(
                                        'Ha de ser un número'
                                    ).required(
                                        'El número de sales és obligatòri'
                                    ),
                                    carrer: Yup.string().required(
                                        'El carrer és obligatòri'
                                    ),
                                    poblacio: Yup.string().required(
                                        'La població és obligatòria'
                                    ),
                                    codi_postal: Yup.number(
                                        'Ha de ser un número'
                                    ).required('El codi postal és obligatòri'),
                                    provincia: Yup.string().required(
                                        'La provincia és obligatòria'
                                    ),
                                    link: Yup.string().required(
                                        'Enllaç obligatòri'
                                    ),
                                })}
                                onSubmit={(values) => {
                                    update(values);
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.nom &&
                                                            errors.nom
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.nom &&
                                                        errors.nom
                                                    }
                                                    label="Nom"
                                                    margin="normal"
                                                    name="nom"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.nom}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.sales &&
                                                            errors.sales
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.sales &&
                                                        errors.sales
                                                    }
                                                    label={'Sales'}
                                                    margin="normal"
                                                    name="sales"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.sales}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.carrer &&
                                                            errors.carrer
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.carrer &&
                                                        errors.carrer
                                                    }
                                                    label={'Carrer'}
                                                    margin="normal"
                                                    name="carrer"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.carrer}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.poblacio &&
                                                            errors.poblacio
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.poblacio &&
                                                        errors.poblacio
                                                    }
                                                    label="Població"
                                                    margin="normal"
                                                    name="poblacio"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.poblacio}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.codi_postal &&
                                                            errors.codi_postal
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.codi_postal &&
                                                        errors.codi_postal
                                                    }
                                                    label={'Còdi Postal'}
                                                    margin="normal"
                                                    name="codi_postal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.codi_postal}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.provincia &&
                                                            errors.provincia
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.provincia &&
                                                        errors.provincia
                                                    }
                                                    label={'Província'}
                                                    margin="normal"
                                                    name="provincia"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.provincia}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    error={Boolean(
                                                        touched.link &&
                                                            errors.link
                                                    )}
                                                    fullWidth
                                                    helperText={
                                                        touched.link &&
                                                        errors.link
                                                    }
                                                    label="Enllaç mapa"
                                                    margin="normal"
                                                    name="link"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.link}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box my={2}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                {'Guardar'}
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Fade>
                ) : (
                    <CircularProgress />
                )}
            </Container>
        </Page>
    );
};

export default TanatoriView;
