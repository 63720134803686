import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContext } from "./database/UserContext";
import { DifuntContext } from "./utils/DifuntContext";
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
import { version } from "../package.json";

ReactDOM.render(
	<CacheBuster
		currentVersion={version}
		isEnabled={true}
		isVerboseMode={false} //If true, the library writes verbose logs to console.
		metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
	>
		<BrowserRouter>
			<UserContext>
				<DifuntContext>
					<App />
				</DifuntContext>
			</UserContext>
		</BrowserRouter>
	</CacheBuster>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
