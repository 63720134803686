import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { Edit } from 'react-feather';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
    item: { paddingTop: 25 },
    text: {
        paddingTop: 5,
        paddingLeft: 10,
    },
    index: {
        width: 20,
    },
    media: {
        height: 0,
        paddingTop: '30.25%', // 16:9
    },
    header: {
        '& .MuiTypography-colorTextSecondary': {
            color: 'black',
            fontWeight: 800,
        },
    },
}));

const FlorAdmin = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { item } = props;

    return (
        <Grid item md={4} xs={12} key={item.sku}>
            <Card className={classes.root}>
                <CardHeader
                    className={classes.header}
                    action={
                        <>
                            <IconButton
                                aria-label="Editar flor"
                                onClick={() =>
                                    navigate(`/admin/flors/${item.id}`)
                                }
                            >
                                <Edit />
                            </IconButton>
                        </>
                    }
                    title={item.nom}
                    subheader={item.preu + '€'}
                />
                <CardMedia
                    className={classes.media}
                    image={
                        'https://api.funerariaferran.com/public/storage/' +
                        item.imatge
                    }
                    title={item.nom}
                />
                <CardContent>
                    <Grid container justify="space-between">
                        <Grid item md={6}>
                            <Typography variant="body2" component="p">
                                {item.mida}
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body2" component="p">
                                {item.flor_categoria?.nom}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default FlorAdmin;
