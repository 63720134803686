import React, { useEffect, useState } from 'react';
import {
    Container,
    Fade,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { Element } from 'react-scroll';
import { getInformacio } from '../../../../database/API';
import Informacio from '../../../../components/Informacio';
import ScrollAnimation from 'react-animate-on-scroll';
import InformacioSkeleton from '../../../../components/Skeletons/InformacioSkeleton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/fons-informacio.jpg")`,
        justifyContent: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'left',
        backgroundPositionY: 'bottom',
        height: '100vh',
    },
    container: {},
}));

const Informacions = (props) => {
    const classes = useStyles();
    const { setLoadingInfo } = props;
    const matches = useMediaQuery('(min-width:960px)');
    const [informacio, setInformacio] = useState();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const get = async () => {
            const { informacio } = await getInformacio();
            setInformacio(informacio);
            setLoading(false);
            setLoadingInfo(false);
        };
        get();
    }, [setLoadingInfo]);

    return (
        <Element name="informacio" className={classes.main}>
            <Container
                maxWidth="lg"
                className={matches ? classes.container : ''}
            >
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Grid container>
                        <Grid item md={4}></Grid>
                        {!loading ? (
                            <Fade in={!loading}>
                                <Grid item md={8}>
                                    <Typography variant="h2">
                                        {t('Informació')}
                                    </Typography>
                                    <Typography>
                                        {t(
                                            "En cas d'una defunció cal seguir diversos passos."
                                        )}
                                    </Typography>
                                    <Grid>
                                        {informacio?.map((item) => {
                                            return (
                                                <Informacio
                                                    key={item.id}
                                                    item={item}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Fade>
                        ) : (
                            <InformacioSkeleton />
                        )}
                    </Grid>
                </ScrollAnimation>
            </Container>
        </Element>
    );
};

export default Informacions;
