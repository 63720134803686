import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useNavigate } from 'react-router-dom';
import { getInformacio } from '../../../database/API';
import Title from '../../../components/Title';
import { Add } from '@material-ui/icons';
import { Edit } from 'react-feather';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    info: {
        marginBottom: 20,
    },
    container: {
        paddingTop: 40,
    },
    text: {
        alignSelf: 'flex-end',
        padding: 4,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const InformacioAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [informacio, setInformacio] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { informacio, message } = await getInformacio();
            setInformacio(informacio);
            console.log(message);
            setLoading(false);
        };

        get();
    }, []);

    return (
        <Page className={classes.root} title={'Informació'}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title={'Informació'}
                    subtitle={
                        "Administra la informació que apareix a la pàgina d'inici"
                    }
                    button={
                        <Tooltip title="Crear punt">
                            <IconButton
                                className={classes.addButton}
                                onClick={() => navigate('nou')}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <>
                            {informacio?.map((item) => {
                                return (
                                    <Paper className={classes.info}>
                                        <Box
                                            p={3}
                                            justifyContent="space-between"
                                            display="flex"
                                        >
                                            <Box
                                                justifyContent="flex-start"
                                                display="flex"
                                            >
                                                <Typography variant="h1">
                                                    {item.index}.
                                                </Typography>
                                                <Typography
                                                    className={classes.text}
                                                >
                                                    {item.text
                                                        .split('\n')
                                                        .map(function (
                                                            item,
                                                            idx
                                                        ) {
                                                            return (
                                                                <span key={idx}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            );
                                                        })}
                                                </Typography>
                                            </Box>
                                            <Grid>
                                                <IconButton
                                                    className={
                                                        classes.addButton
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `/admin/informacio/${item.id}`,
                                                            {
                                                                state: item,
                                                            }
                                                        )
                                                    }
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                );
                            })}
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default InformacioAdmin;
