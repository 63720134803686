import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Container,
    createMuiTheme,
    Fade,
    IconButton,
    makeStyles,
    MuiThemeProvider,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteTanatori, getTanatoris } from '../../../database/API';
import MUIDataTable from 'mui-datatables';
import Columns from './columns/TanatorisColumns';
import Title from '../../../components/Title';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            TableHead: {
                root: {
                    fontSize: 23,
                },
            },
        },
    });

const TanatoriAdmin = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [tanatoris, setTanatoris] = useState();
    const [loading, setLoading] = useState(true);
    const columns = Columns();
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const get = async () => {
            const { tanatoris } = await getTanatoris();
            setTanatoris(tanatoris);
            console.log(state);
            setLoading(false);
        };

        get();
    }, [state]);

    const options = {
        filterType: 'checkbox',
        onRowClick: (rowData) => {
            navigate(`/admin/tanatoris/${rowData[0]}`, {
                state: rowData,
            });
        },
        onRowsDelete: (rowsDeleted) => {
            rowsDeleted.data.forEach(async (item) => {
                let message = await deleteTanatori(
                    tanatoris[item.dataIndex].id
                );
                enqueueSnackbar(message, {
                    variant: 'success',
                });
                console.log(message);
            });
        },
        textLabels: {
            body: {
                noMatch: 'No hi han tanatoris',
                toolTip: 'Ordenar',
                columnHeaderTooltip: (column) =>
                    `${'Ordenat per'} ${column.label}`,
            },
            pagination: {
                next: 'Següent pàgina',
                previous: 'Pàgina anterior',
                rowsPerPage: 'Files per pàgina:',
                displayRows: 'de', // 1-10 of 30
            },
            toolbar: {
                search: 'Cercar',
                downloadCsv: 'Descarregar CSV',
                print: 'Imprimir',
                viewColumns: 'Veure columnes',
                filterTable: 'Filtrar taula',
            },
            filter: {
                title: 'FILTRES',
                reset: 'resetejar',
                all: 'Tots',
            },
            viewColumns: {
                title: 'Mostrar columnes',
            },
            selectedRows: {
                text: 'Fila/es eliminades',
                delete: 'Eliminar',
            },
        },
    };

    return (
        <Page className={classes.root} title={'Tanatoris'}>
            <Container maxWidth={false} className={classes.container}>
                <Title
                    title={'Tanatoris'}
                    subtitle={'Administra els tanatoris'}
                    button={
                        <Tooltip title="Crear tanatori">
                            <IconButton
                                className={classes.addButton}
                                onClick={() => navigate('nou')}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <Box pt={2} spacing={3}>
                    {!loading ? (
                        <Fade in={!loading}>
                            <div>
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        data={tanatoris}
                                        columns={columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </Fade>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default TanatoriAdmin;
