import { Switch } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { changeEslogan } from '../../../../database/API';

const Columns = () => {
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            name: 'id',
            label: 'id',
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: 'titol',
            label: 'Títol',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'publicat',
            label: 'Publicat',
            options: {
                filter: true,
                sort: true,
                sortOrder: 'asc',
                customBodyRender: (value, tableMeta, updateValue) => {
                    console.log(value);
                    return (
                        <Tooltip
                            title={value === 1 ? 'Despublicar' : 'Publicar'}
                        >
                            <FormControlLabel
                                // label={value === "1" ? "Sí" : "No"}
                                value={value === 1 ? 1 : 0}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value === 1}
                                        value={value === 1 ? '1' : '0'}
                                    />
                                }
                                onChange={async (event) => {
                                    await changeEslogan(tableMeta.rowData[0]);
                                    // console.log(tableMeta);
                                    enqueueSnackbar('Elogan actualitzat', {
                                        variant: 'success',
                                    });
                                    updateValue(
                                        event.target.value === '1' ? 0 : 1
                                    );
                                }}
                            />
                        </Tooltip>
                    );
                },
            },
        },
    ];
    return columns;
};

export default Columns;
