import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const TanatorisSkeleton = () => {
    return (
        <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="rect" height={80} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="rect" height={80} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="rect" height={80} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Skeleton animation="wave" variant="text" />
                <Skeleton animation="wave" variant="rect" height={80} />
            </Grid>
        </Grid>
    );
};

export default TanatorisSkeleton;
